import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import "./SearchBar.css";
import axios from "axios";
import { Select } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { getAllCourseByStatusApi } from "../../../api/Course";
import { axiosInstance } from "../../../api/axiosInstance";

export const SearchBar = () => {
  const navigate = useNavigate();
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("")
  useEffect(() => {
    fetchData();
  }, []);



  const fetchData = async () => {
    try {
      setLoading(true);
      const responsev1 = await axiosInstance.post("/branches/getAll", {
        page: 0,
        pageSize: 1
      })
      const responsev2 = await axiosInstance.post("/branches/getAll", {
        page: 0,
        pageSize: responsev1.data.count
      })
 
      setCourseList(responsev2?.data.items?.filter(el => el?.coursePreviewStatus))


    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = (value) => {
    const selectedCourse = courseList.find((course) => course.name === value);
    if (selectedCourse) {
      navigate(`/kurs/${selectedCourse?.label}`);
    } else {

    }
  };

  return (
    <div>
      <StyledSelect
        suffixIcon={<FaSearch />}
        showSearch
        placeholder="Kurs ara"
        filterOption={(input, option) => {
          return option?.label?.toLocaleLowerCase().search(input.toLocaleLowerCase()) != -1
        }
        }
        options={
          searchText != "" ? courseList.map(item => {
            return {
              "label": item.name,
              "value": item.name
            }
          }) : []
        }
        notFoundContent={searchText == "" ? "" : "Kurs bulunamadı"}
        loading={loading}
        onChange={handleNavigate}
        onSearch={setSearchText}
      />

    </div>
  );
};

SearchBar.propTypes = {
  setResults: PropTypes.array,
};

const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 20px;
  font-size: 12px !important;
  color: red !important;
  display: flex;
  align-items: center;
  .ant-select-selector {
    height: 40px !important;
  }
`;

import React, { useContext, useEffect } from 'react'
import './assets/scss/themes.scss';
import Route from './Routes/index';
import { ConfigProvider } from 'antd';
import trTR from 'antd/locale/tr_TR';
import { useLocation } from 'react-router-dom';
/* test commit */



const App = () => {
  /* document.title = "Kuzem Eğitim Platformuna Hoş Geldiniz" */
  const location = useLocation();
  /* const [state, dispatch] = useContext(UserContext);

  const authToken = localStorage.getItem("authToken")

  useEffect(() => {
    console.log("auth", authToken)
    const tokenControl = isTokenValid(authToken);
    if (!tokenControl) {
      localStorage.removeItem("authToken");
      dispatch({ type: "LOGOUT" })
    }
  }, [authToken]); */

  useEffect(() => {
    if (window.ym) {
      window.ym("98428588", "hit", location.pathname);
    }
  }, [location]);


  return (
    <>
      <ConfigProvider locale={trTR}>
        <Route />
      </ConfigProvider>

    </>

  )
}

export default App
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { GetCalendarForAllRoles } from '../../../api/Course'
import "./CreateExam.scss"
import { Button, Modal, ModalFooter, ModalBody, ModalHeader, Input, Table, Label, Row, Col, Spinner } from "reactstrap"
import { DatePicker, Select, Button as AntButton, Modal as AntModal } from "antd"
import { uniqueId } from 'lodash'
import { toast } from "react-toastify"
import { ExamContext } from '../ExamManagementDashboard'
import ExamDragAndDrop from './ExamDragAndDrop'
import * as yup from "yup"
import { createExamAnswerApi, createExamApi, createExamQuestionApi } from '../../../api/exam'
import CreateQuestionModal from '../CreateExamQuestionModal.jsx/CreateQuestionModal'
import styled from 'styled-components'
import QuestionPool from './QuestionPool'
import { axiosInstance } from '../../../api/axiosInstance'


const ExamValidSchema = yup.object({
  course: yup.string().required("Lütfen kurs seçiniz"),
  examName: yup.string().required("Sınav ismi zorunludur"),
  description: yup.string().required("Açıklama zorunludur"),
  startTime: yup
    .date()
    .required("Başlangıç saati zorunludur")
    .test('start-date', 'Başlangıç tarihi bitiş tarihinden önce ve günümüzden ileride olmalıdır', function (value) {
      const endTime = this.parent.endTime;
      return endTime && new Date(value) < new Date(endTime) && new Date() < new Date(value);
    }),
  endTime: yup
    .date()
    .required("Bitiş saati zorunludur")
    .test('end-date', 'Bitiş tarihi başlangıç tarihinden sonra ve günümüzden ileride olmalıdır', function (value) {
      const startTime = this.parent.startTime;
      return startTime && new Date(startTime) < new Date(value) && new Date() < new Date(value);
    }),
})

const ExamValidSchemaForPrelim = yup.object({
  course: yup.string().required("Lütfen kurs seçiniz"),
  examName: yup.string().required("Sınav ismi zorunludur"),
  description: yup.string().required("Açıklama zorunludur"),

})


const CreateExam = () => {
  const { examData, setExamData, modalChoiceList, setQuestionPoolModal, previewSrc, setPreviewSrc, setModalChoiceList, editMode, setEditMode, modalQuestion, setModalQuestion, modalShow, setModalShow, courseList, setCourseList, questionPoint, setQuestionPoint } = useContext(ExamContext)
  const [loading, setLoading] = useState(false)

  const getCourseList = async () => {
    try {
      const response = await GetCalendarForAllRoles({
        page: 0,
        pageSize: 100
      })
      const responsev2 = await GetCalendarForAllRoles({
        page: 0,
        pageSize: response.data.count + 1
      })

      setCourseList(responsev2.data.items)
    }
    catch (err) {
    }
    finally {

    }
  }

  async function validExam() {
    try {
      if (examData.formal !== "Ön Sınav") {
        if (examData.startTime == null) {
          toast.error("Başlangıç saati zorunludur", {
            autoClose: 1500
          })
          return false;
        }
        else if (examData.endTime == null) {
          toast.error("Bitiş saati zorunludur", {
            autoClose: 1500
          })
          return false;
        }
      }
      if (examData.formal == "Ön Sınav") {
        await ExamValidSchemaForPrelim.validate(examData)
      }
      else {
        await ExamValidSchema.validate(examData)
      }

      if (examData.questions.length == 0) {
        toast.error("En az bir adet soru oluşturunuz", {
          autoClose: 1500
        })
        return false;
      }
      let questionTypeControl = examData.questions.every((x) => x.questionType == examData.type)

      if (!questionTypeControl) {
        toast.error(`Sorular arasında ${examData.type} formatına uymayanları kaldırınız`, {
          autoClose: 2000
        })
        return false;
      }
      return true;
    }
    catch (err) {
      toast.error(err.errors[0], {
        autoClose: 1200
      })
      return false;
    }
  }


  const sumPoint = useMemo(() => {
    return examData?.questions?.reduce((acc, el) => acc += el?.point, 0)
  }, [examData])


  async function createExamProcess() {

    const validControl = await validExam()
    const sumPoint = examData?.questions.reduce((acc, el) => acc += el.point, 0)

    if (sumPoint > 100 || sumPoint < 0) {
      toast.error("Sınavın toplam puanı 0 ile 100 arasında olmalı", {
        autoClose: 2000
      })
      return
    }
    if (validControl) {

      AntModal.confirm({
        title: "Sınavı oluşturmak istediğinize emin misiniz?",
        okText: "Eminim",
        cancelText: "İptal",
        onOk: async () => {
          try {
            setLoading(true)

            const response = await createExamApi({
              "name": examData.examName.trim(),
              "description": examData.description.trim(),
              "startTime": examData.formal == "Ön Sınav" ? new Date().toUTCString() : new Date(examData.startTime).toUTCString(),
              "endTime": examData.formal == "Ön Sınav" ? new Date().toUTCString() : new Date(examData.endTime).toUTCString(),
              "type": examData.type,
              "courseId": examData.course,
              "formal": examData.formal
            })

            let formatQuestionResponse = []
            for (let i of examData.questions) {
              if (i?.poolFlag) {
                await axiosInstance.post("/ExamQuestions/AddForBranchQuestion", {
                  examId: response.data.id,
                  branchQuestionId: i?.id
                })
              }
              else {
                const formData = new FormData()
                if (i.file) {
                  formData.append("formFile", i.file)
                }
                let resp = await createExamQuestionApi(formData, response.data.id, i.point, i.questionContent)
                formatQuestionResponse.push(resp.data)

              }

            }


            if (examData.type == "Test") {
              for (let i = 0; i < formatQuestionResponse.length; i++) {
                let id = formatQuestionResponse[i]["id"]
                let selectedQuestionChoices = examData.questions[i]["choices"]

                for (let el of selectedQuestionChoices) {
                  await createExamAnswerApi({
                    "answer": el.content,
                    "option": el.letter,
                    "examQuestionId": id,
                    "status": el.correct
                  });
                }


              }
            }


            setExamData({
              "course": "",
              "questions": [],
              "examName": "",
              "description": "",
              "type": "Test",
              "startTime": null,
              "endTime": null
            })
            setQuestionPoint(0)
            setModalQuestion("")
            setModalChoiceList([{
              "content": "",
              "id": 984,
              "correct": false,
            },
            {
              "content": "",
              "id": uniqueId(),
              "correct": false,
            },
            {
              "content": "",
              "id": uniqueId(),
              "correct": false,
            },
            {
              "content": "",
              "id": uniqueId(),
              "correct": false,
            },
            {
              "content": "",
              "id": uniqueId(),
              "correct": false,
            }])
            setPreviewSrc(null)

            toast.success(`${examData.examName} isimli sınav başarıyla oluşturuldu`, {
              autoClose: 4500
            })

          }
          catch (err) {

          }
          finally {
            setLoading(false)
          }
        }
      })


    }
  }

  /* (
    <p  >
      {`${el?.name}`} <span style={{ color: el?.type == "Online" ? "green" : "red" }}>({`${el?.type}`})</span>

    </p>
  ) */

  const CourseOptionSelect = () => {

    if (courseList) {
      return [
        { "label": "Kurs Seçiniz", value: "" },
        ...courseList?.map(el => ({
          "label": `${el?.id}-${el.name} (${el.type}) `,
          "value": el.id
        }))
      ]
    }
    else {
      return [
        { "label": "Kurs Seçiniz", value: "" }
      ]

    }
  }


  useEffect(() => {
    getCourseList()
  }, [])

  return (
    <>
      <div className='create_exam_container' >
        <div className='create_exam_header' >

          <Select
            showSearch
            status='warning'
            defaultValue={""}
            value={examData.course}
            filterOption={(input, option) => {

              return (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())

            }

            }
            onChange={(e) => {
              let selected = courseList?.find(el => el?.id == e)
              setExamData({
                ...examData,
                course: e,
                branch: selected?.branch
              })
            }}
            className='create_exam_select_course'
            options={CourseOptionSelect()}
          />

          <AddQuestionBtnContainer>
            <AntButton type='primary' onClick={() => {
              setModalQuestion("")
              setQuestionPoint(0)

              setModalShow(true)
              setEditMode({
                active: false,
                id: 0
              })
            }} disabled={examData.course == ""}  >
              <p style={{ whiteSpace: "nowrap" }}>Soru Ekle</p>
            </AntButton>
            <AntButton disabled={examData.course == ""} type='primary' onClick={() => {
              setQuestionPoolModal({
                visible: true,
                data: []
              })
            }} >
              Soru Havuzu
            </AntButton>
          </AddQuestionBtnContainer>

        </div>



        <div className='create_exam_content_container' >
          <div className='exam_general_info_container' >
            <div className='exam_general_info_input_wrapper' >
              <Label>
                Sınav İsmi
              </Label>
              <StyledInput value={examData.examName} placeholder='Sınav ismi' maxLength={30} style={{ marginLeft: 0 }} onChange={(e) => {
                setExamData({
                  ...examData,
                  "examName": e.target.value
                })
              }} />
            </div>
            <div className='exam_general_info_input_wrapper' >
              <Label>
                Açıklama
              </Label>
              <Input value={examData.description} type='textarea' rows={1.7} maxLength={150} style={{ marginLeft: 0, resize: "none" }} onChange={(e) => {
                setExamData({
                  ...examData,
                  "description": e.target.value
                })
              }} />
            </div>
            <div className='exam_general_info_input_wrapper' >
              <Label className='form-label' >
                Sınav Tipi
              </Label>
              <Select value={examData.type} onChange={(e) => {
                setExamData({
                  ...examData,
                  type: e
                })
              }} >
                <option value="Test">
                  Test
                </option>
                <option value="Yazılı">
                  Yazılı
                </option>
              </Select>
            </div>
            <div className='exam_general_info_input_wrapper' >
              <Label className='form-label' >
                Sınav Şekli
              </Label>
              <Select value={examData.formal} onChange={(e) => {
                setExamData({
                  ...examData,
                  formal: e
                })
              }} >
                <option value="Ön Sınav">
                  Ön Sınav
                </option>
                <option value="Vize">
                  Vize
                </option>
                <option value="Final">
                  Fİnal
                </option>
              </Select>
            </div>
            {
              examData.formal !== "Ön Sınav" && (
                <div className='exam_general_info_input_wrapper' >
                  <Label className='form-label' >
                    Başlangıç Saati
                  </Label>
                  <DatePicker
                    className='datapickers'
                    allowClear={false}
                    value={examData.startTime}
                    showTime
                    onChange={(value, dateString) => {
                      setExamData({
                        ...examData,
                        "startTime": value
                      })

                    }}
                    popupClassName='custom-datepicker-popup'
                  />
                </div>
              )
            }
            {
              examData.formal !== "Ön Sınav" && (
                <div className='exam_general_info_input_wrapper' >
                  <Label className='form-label' >
                    Bitiş Saati
                  </Label>
                  <DatePicker
                    className='datapickers'

                    allowClear={false}
                    showTime
                    value={examData.endTime}
                    onChange={(value, dateString) => {
                      setExamData({
                        ...examData,
                        endTime: value
                      })
                      /*  formik.setFieldValue("startDate", value) */
                    }}
                  /*       onBlur={formik.handleBlur}
                        value={formik.values.startDate} */
                  />
                </div>

              )
            }

            <AntButton type='primary' disabled={loading} className='mt-4' onClick={() => {

              createExamProcess()
            }} >
              {loading ? (
                <span>
                  Oluşturuluyor <Spinner size={"sm"} style={{ marginLeft: "10px" }} />
                </span>
              ) : "Sınav oluştur"}
            </AntButton>

            <div className='create_exam_sumPoint' >
              Toplam Puan: {sumPoint}
            </div>
          </div>



          <div className='create_exam_question_wrapper'  >
            <div className='teacher_question_table' >
              <ExamDragAndDrop />
            </div>

          </div>
        </div>
      </div>
      <CreateQuestionModal />
      {
        examData.course != "" && (
          <QuestionPool />
        )
      }
    </>
  )
}

const StyledInput = styled(Input)`
  height: 30px;
`
const StyledSelectCourse = styled(Select)`
  width: 300px;
`

const AddQuestionBtnContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export default CreateExam
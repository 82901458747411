import { SlArrowDown } from 'react-icons/sl'
import "./customAccordionHeader.scss"
import test from "../../../assets/images/b1.png"
import { Button as AntButton } from "antd"
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'


const CustomAccordionHeader = (element, navigate) => {

    return (
        <div className='course_list_dashboard_accordion_header_item' >
            <img src={test} alt="" className='course_list_dashboard_accordion_header_item_img' />
            <div className='course_list_header_content' >
                <p className='course_list_header_content_title' >
                    {element?.name}  <span style={{ color: "#2E81B7", marginLeft: "10px", display: "inline-block" }}>
                        #{element?.id}
                    </span>
                </p>
                {
                    element?.type == "Offline" && (
                        <StyledAntButton type='primary' onClick={(e) => {
                            e.stopPropagation(); // Collapse menüsünün açılmasını engelle
                            navigate(`/kurs/${element?.branch?.label}`)
                        }} >
                            Kursa Git
                        </StyledAntButton>
                    )
                }

            </div>
            <SlArrowDown className='collapse_header_arrow_down_icon' />
        </div>
    );
}

export default CustomAccordionHeader

const StyledAntButton = styled(AntButton)`
    margin-top: 10px;
`
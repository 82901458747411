import React, { useContext, useEffect, useMemo, useState, useRef } from 'react'
import { IoIosSend, IoMdRefresh } from 'react-icons/io'
import { Button, Col, Input, Row } from 'reactstrap'
import { GetAllTeacherAndStudentNotification } from '../../../api/Course'
import { getAllReceivedNotification, getAllSendNotifications, sendMessageApi } from '../../../api/Notifications'
import { UserContext } from "../../../context/user";
import { Select } from "antd"
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import Linkify from "react-linkify"
import { toast } from 'react-toastify'

const StudentMessage = () => {
    const [message, setMessage] = useState("")
    const [teachers, setTeachers] = useState([])
    const [messageList, setMessageList] = useState([])
    const [selectedTeachers, setSelectedTeachers] = useState(null)
    const [context, dispatch] = useContext(UserContext)
    const messageContentRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("userId");

    const getTeacherList = async () => {
        try {
            const response = await GetAllTeacherAndStudentNotification({
                page: 0,
                pageSize: 1000
            })

            const temp = response.data.items.map(el => el.user)
            let uniqueUsers = [...new Map(temp.map(user => [user?.id, user])).values()];
            console.log("unique", uniqueUsers)
            setTeachers(uniqueUsers.filter(el => el?.firstName !== "Kurs sorumlusu bulunamadı."))
        }
        catch (err) {

        }

    }


    const getMessageList = async () => {
        try {
            const sendingMessage = await getAllSendNotifications({
                page: 0,
                pageSize: 1000000
            })
            const receivedMessage = await getAllReceivedNotification({
                page: 0,
                pageSize: 100000
            })

            let sortDataList = [...sendingMessage.data.items, ...receivedMessage.data.items].sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt)
            })
            setMessageList(sortDataList)

        }
        catch (err) {


        }


    }
    useEffect(() => {
        messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight;
    }, [messageList, selectedTeachers])

    useEffect(() => {
        getTeacherList()
        getMessageList()
    }, [])

    const selectedMessage = useMemo(() => {
        const filterData = messageList.filter(el => el.receiverUserId == selectedTeachers || el.sentUserId == selectedTeachers)
        return filterData
    }, [messageList, selectedTeachers])

    const sendMessage = async () => {
        try {
            if (message == "") {
                toast.error("Boş mesaj yollanamaz", {
                    position: "top-center",
                    autoClose: 2000
                })
            }
            else if (selectedTeachers == "" || selectedTeachers == null || selectedTeachers == undefined) {
                toast.error("Mesaj atılacak kişiyi seçiniz", {
                    position: "top-center",
                    autoClose: 2000
                })
            }
            else {
                const response = await sendMessageApi({
                    userId: parseInt(context.userId),
                    receiverIds: [selectedTeachers],
                    message: message
                })
                setMessage("")
                toast.success("Mesaj iletildi", {
                    position: "top-center",
                    autoClose: 2000
                })
                await getMessageList()
            }

        }
        catch (err) {
            console.log("err", err)
        }
    }

    const getFullName = () => {
        const findUser = teachers.find(item => item?.id == selectedTeachers)
        if (findUser) {
            return `${findUser?.firstName} ${findUser?.lastName}`
        }
        return ""
    }

    const componentDecorator = (href, text, key) => (
        <StyledAhrefTag href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </StyledAhrefTag>
    );

    useEffect(() => {

        setSelectedTeachers(userId)

    }, [teachers])


    useEffect(() => {
        // Enter tuşuna basıldığında tetiklenecek olay
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                sendMessage()
                // Enter tuşuna basıldığında yapmak istediğin işlemi buraya ekle
            }
        };

        // `keydown` olay dinleyicisini ekle
        window.addEventListener('keydown', handleKeyDown);

        // Component unmount olduğunda event listener'ı kaldır
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [message]);

    return (
        <div>
            <Row style={{ padding: 10 }} >
                <Col lg={12} >
                    <div className='message_content_section' ref={messageContentRef} >
                        <div className='message_list' >
                            <div className="sending_msg_title">
                                <StyledTeacherSelect
                                    showSearch
                                    value={getFullName()}
                                    onChange={(e) => {

                                        setSelectedTeachers(e)

                                    }}
                                    filterOption={(input, option) => {
                                        return (
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                        )
                                    }}
                                    options={teachers.map(el => {
                                        return {
                                            label: `${el.firstName} ${el.lastName}`,
                                            value: el.id,
                                        }
                                    })}
                                />
                                <Button className='btn btn-warning' style={{ borderRadius: "0", zIndex: "100000" }} onClick={async () => {
                                    messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight;
                                    getMessageList()
                                }} >
                                    <IoMdRefresh />
                                </Button>

                            </div>
                            <SendingMessageContainer   >
                                {
                                    selectedMessage.map((el, index) => {

                                        if (el.sentUserId == context.userId) {
                                            return (

                                                <SendingMessage key={`${index}`} >
                                                    {el.message?.trim()}
                                                </SendingMessage>


                                            )
                                        }
                                        else {
                                            return (

                                                <ReceivedMessage key={`${index}`}  >
                                                    {el.message?.trim()}
                                                </ReceivedMessage>


                                            )
                                        }
                                    })
                                }
                            </SendingMessageContainer>

                        </div>
                        <div className='message_input_container'>
                            <Input placeholder='Mesaj' value={message} className='message_input' onChange={(e) => {
                                setMessage(e.target.value)
                            }} />
                            <Button className='send_message_btn' disabled={message == ""} onClick={() => {
                                sendMessage()
                            }} >
                                <IoIosSend className='send_icon' />
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default StudentMessage


const SendingMessage = styled.h5`

                text-align: right;
                padding: 10px 10px;
                background-color: #33A293;
                align-self: flex-end;
                color: white;
                margin-bottom: 10px;
                font-size: 12px;
                border-radius: 10px;
                white-space: pre-line;
                max-width: 1000px;
`
const ReceivedMessage = styled.h5`
               
               text-align: left;
                padding: 10px 10px;
                background-color: #3577F1;
                align-self: flex-start;
                color: white;
                margin-bottom: 10px;
                font-size: 13px;
                border-radius: 10px;
                white-space: pre-line;
                max-width: 1000px;
                

`

const SendingMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledAhrefTag = styled.a`

    border-bottom: 1px solid white;
    color: white;
    transition: .3s all;
    z-index: -1;
    &:hover{
        color: white;
        opacity: .7;
    }
`

const StyledTeacherSelect = styled(Select)`
    width: 250px;
`
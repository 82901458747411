import React, { useContext, useEffect, useMemo, useState } from 'react';
import "./examListDashboard.scss";
import DataTable from 'react-data-table-component';
import { Button, Card, Input } from 'reactstrap';
import { getExamListApi } from '../../../api/exam';
import { formatDate } from "date-fns";
import trLocale from "date-fns/locale/tr";
import { BsArrowDown } from 'react-icons/bs';
import { ExamContext } from '../ExamManagementDashboard';
import { FiEdit } from 'react-icons/fi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Select, Input as AntInput, Table as AntTable, Button as AntButton } from 'antd';

const ExamListDashboard = () => {

    const { examListGetALL, loading, setLoading, examList } = useContext(ExamContext)
    const navigate = useNavigate()
    const location = useLocation()
    const [searchTextName, setSearchTextName] = useState("")
    const [selectedRow, setSelectedRow] = useState(null); // Seçili satırı tutan state
    const [selectValue, setSelectValue] = useState("Test")
    const convertTrFormat = (time) => {
        return formatDate(new Date(time), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
    };



    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchTextName(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchTextName('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <AntInput
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    const mainData = useMemo(() => {
        return examList?.filter(item => item.name.toLowerCase().search(searchTextName.toLowerCase()) != -1)
    }, [examList, searchTextName])


    const changeExamType = (type) => {
        examListGetALL(type)

    }



    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const active = query.get("active")
        if (active == 1) {
            examListGetALL("Test");
        }
        else if (active == 2) {
            examListGetALL("Yazılı")
            setSelectValue("Yazılı")
        }
        else {
            examListGetALL("Test")
        }
    }, []);

    return (
        <div className='exam_list_dashboar_container'>
            <div className='exam_list_dashboard_filter'>

                <Select
                    value={selectValue}
                    className='exam_list_dashboard_ant_select'
                    onChange={(e) => {
                        changeExamType(e)
                        setSelectValue(e)
                    }}
                    options={[
                        {
                            value: "Test",
                            label: "Test"
                        },
                        {
                            value: "Yazılı",
                            label: "Yazılı"
                        }
                    ]}
                />
                <AntInput className='exam_list_dashboard_ant_filter' placeholder='Ara' onChange={(e) => {
                    setSearchTextName(e.target.value)
                }} />

            </div>
            <AntTable
                scroll={{
                    x: 800
                }}
                pagination={{
                    pageSize: 10
                }}
                size='middle'
                dataSource={mainData}
                columns={[
                    {
                        title: "İsim",
                        dataIndex: "name",
                        render: (row, record) => {
                            return (
                                <p>{record?.name?.length < 20 ? record?.name : `${record.name.substring(0, 20)}...`}</p>
                            )
                        },
                        ...getColumnSearchProps("name")
                    },
                    {
                        title: "Sınav Tipi",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.type}
                                </p>
                            )
                        },
                    },
                    {
                        title: "Kurs",
                        dataIndex: "courseName",
                        render: (row, record) => {
                            return (
                                <p>{record?.courseName?.length < 20 ? record?.courseName : `${record?.courseName?.substring(0, 20)}...`}</p>
                            )
                        },
                        ...getColumnSearchProps("courseName")
                    },
                    {
                        title: "Başlangıç Saati",
                        render: (row, record) => {
                            return (
                                <p>{convertTrFormat(record.startTime)}</p>
                            )
                        },
                        sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime)
                    },
                    {
                        title: "Bitiş Saati",
                        render: (row, record) => {
                            return (
                                <p>{convertTrFormat(record.endTime)}</p>
                            )
                        },
                        sorter: (a, b) => new Date(a.endTime) - new Date(b.endTime)
                    },
                    {
                        title: "işlem",
                        render: (row, record) => {
                            return (
                                <Button color='warning' onClick={() => {
                                    navigate(`/panel/sinav/duzenle/${record.id}`)
                                }} >
                                    <FiEdit />
                                </Button>
                            )
                        }
                    }

                ]}
            />

            {/* <DataTable
                data={mainData}
                columns={columns}
                pagination
                sortIcon={<BsArrowDown />}
                noDataComponent={
                    <Card className="w-100 p-5" style={{ marginRight: 0 }}>
                        <center>
                            <h2>Herhangi bir veri bulunamadı.</h2>
                        </center>
                    </Card>
                }
                paginationComponentOptions={{
                    rowsPerPageText: "Satır Sayısı",
                    rangeSeparatorText: "-",
                    noRowsPerPage: true
                }}
                progressPending={loading}
                customStyles={{
                    headCells: {
                        style: {

                            backgroundColor: "rgba(128, 128, 128, 0)"
                        }
                    }
                }}


            /> */}


        </div>
    );
};

export default ExamListDashboard;

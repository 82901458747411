import React, { useState } from 'react';
import { Flex, Switch, Table, Tag, Transfer } from 'antd';
import styled from "styled-components"
// Customize Table Transfer
const TableTransfer = (props) => {
    const { leftColumns, rightColumns, ...restProps } = props;
    return (
        <Transfer
            style={{
                width: '100%',
            }}
            {...restProps}
        >
            {({
                filteredItems,
                onItemSelect,
                onItemSelectAll,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const rowSelection = {
                    getCheckboxProps: () => ({
                        disabled: listDisabled,
                    }),
                    onChange(selectedRowKeys) {
                        onItemSelectAll(selectedRowKeys, 'replace');
                    },
                    selectedRowKeys: listSelectedKeys,
                    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
                };
                return (
                    <Table
                        pagination={{
                            pageSize: 10
                        }}
                        rowSelection={rowSelection}
                        columns={[
                            {
                                title: "İsim",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.firstName}
                                        </p>
                                    )
                                }
                            }
                        ]}
                        dataSource={filteredItems}
                        size="small"
                        style={{
                            pointerEvents: listDisabled ? 'none' : undefined,
                        }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) {
                                    return;
                                }
                                onItemSelect(key, !listSelectedKeys.includes(key));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );
};
const mockTags = ['cat', 'dog', 'bird'];
const mockData = Array.from({
    length: 20,
}).map((_, i) => ({
    key: i.toString(),
    title: `content${i + 1}`,
    description: `description of content${i + 1}`,
    tag: mockTags[i % 3],
}));
const columns = [
    {
        dataIndex: 'title',
        title: 'Name',
    },

];


const MainTableTransfer = ({ studentList }) => {
    const [targetKeys, setTargetKeys] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };
  
    const toggleDisabled = (checked) => {
        setDisabled(checked);
    };

    return (
        <StyledTableWrapper>
            <StyledSelectStudenTable
                
            />

        </StyledTableWrapper>

    );
};
export default MainTableTransfer;


const StyledSearchWrapper = styled.div`
    display: flex;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    
`
const StyledTableWrapper = styled.div`
    display: flex;
    column-gap: 10px;
`

const StyledSelectStudenTable = styled(Table)`

`
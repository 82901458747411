import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useParams } from "react-router-dom"
import { Card } from 'reactstrap'
import { axiosInstance } from '../../../api/axiosInstance'
import { Table as AntTable } from "antd"

const StudentTakeCourse = () => {
    const { id } = useParams()
    const [takeCourseList, setTakeCourseList] = useState([])
    const [loading, setLoading] = useState(false)
    const getUserCourse = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post(`/courseRegisters/GetAllForUserId?userId=${id}`, {
                page: 0,
                pageSize: 100
            })
     

            setTakeCourseList(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getUserCourse()
    }, [])

    return (
        <div>
            <AntTable
                loading={loading}
                dataSource={takeCourseList}
                pagination={{
                    pageSize: 5
                }}
                scroll={{
                    x: 500
                }}
                size='middle'
                columns={[
                    {
                        title: "Kurs İsmi",
                        render: (row, record) => {
                            return (
                                <span> {record?.courseName} </span>
                            )
                        }
                    },
                    {
                        title: "Tür",
                        render: (row, record) => {
                            return (
                                <span style={{ textTransform: "capitalize" }}> {record?.courseType} </span>
                            )
                        }
                    },
                    {
                        title: "Durum",
                        render: (row, record) => {
                            return (
                                <span style={{ textTransform: "capitalize" }}> {row?.statusDescription} </span>
                            )
                        }
                    }
                ]}
            />

        </div>
    )
}

export default StudentTakeCourse
import { Button, Input, Modal, Table } from 'antd'
import Quill from "quill"
import 'react-quill/dist/quill.snow.css';
import { Modal as RModal, ModalHeader as RModalHeader, ModalFooter as RModalFooter, ModalBody as RModalBody } from "reactstrap"
import React, { useEffect, useRef, useState } from 'react'
import { FaEdit, FaFile, FaImage, FaPlus, FaTrash } from 'react-icons/fa'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'
import axios from 'axios'
import { toast } from 'react-toastify'

const Delta = Quill.import('delta')

const BlogDashboard = () => {
    const [blogData, setBlogData] = useState([])
    const [modalInfo, setMOdalInfo] = useState({
        visible: false,
        editMode: false
    })
    const [loading, setLoading] = useState(false)
    const [addBlogData, setAddBlogData] = useState({
        title: "",
        description: "",
        id: ""
    })
    const [blogImg, setBlogImg] = useState()
    const [fileInfo, setFileInfo] = useState({
        name: "",
        file: null
    })
    const blogRef = useRef()

    const fetchBlogAll = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/blogs/getall", {
                page: 0,
                pageSize: 100
            })

            setBlogData(response.data.items)

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const fetchAddBlog = async () => {
        try {
            setLoading(true)
            const form = new FormData()
            if (blogImg) {
                form.append("formFile", fileInfo.file)
            }

            const response = await axiosInstance.post(`/blogs/add?description=${addBlogData.description}&title=${addBlogData.title}`, form)
            toast.success("İçerik eklendi", {
                autoClose: 2000,
                position: "top-center"
            })
            fetchBlogAll()

            setMOdalInfo({
                visible: false,
                editMode: false
            })
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const PreviewTopicImg = () => {
        if (fileInfo.file instanceof File) {
            window.open(URL.createObjectURL(fileInfo.file), "_blank")
        }
        else {
            window.open(fileInfo.file, "_blank")
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setFileInfo({
                    name: file.name,
                    file: file
                })

                setBlogImg(URL.createObjectURL(file));

            } else {
                setBlogImg(null);
                toast.error("Lütfen fotoğraf seçiniz", {
                    autoClose: 2000,
                    position: "top-center"
                })
            }
        }
    }

    const fetchUpdateBlog = async () => {
        try {
            setLoading(true)
            if (fileInfo.file instanceof File) {
                const form = new FormData()
                form.append("formFile", fileInfo.file)
                const response = await axiosInstance.put(`/blogs/update?description=${addBlogData.description}&title=${addBlogData.title}&id=${addBlogData.id}`, form)

                setAddBlogData({
                    description: "",
                    id: "",
                    title: ""
                })
                setMOdalInfo({
                    editMode: false,
                    visible: false
                })
                toast.success("Güncelleme başarılı", {
                    autoClose: 2000,
                    position: "top-center",

                })
                await fetchBlogAll()
            }
            else {
                const response = await axiosInstance.put(`/blogs/update?description=${addBlogData.description}&title=${addBlogData.title}&id=${addBlogData.id}`)

                setAddBlogData({
                    description: "",
                    id: "",
                    title: ""
                })
                setMOdalInfo({
                    editMode: false,
                    visible: false
                })
                toast.success("Güncelleme başarılı", {
                    autoClose: 2000,
                    position: "top-center",

                })
                await fetchBlogAll()


            }

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }


    }

    const [editorContent, setEditorContent] = useState('');
    const quillRef = useRef(null); // Quill editörünü DOM'da referans almak için kullanılır

    useEffect(() => {
        if (quillRef.current) {
            // Quill'i başlat
            const quill = new Quill(quillRef.current, {
                theme: 'snow', // Quill'in tema seçimi,

                formats: [
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'align', 'color', 'background',
                    'link', 'image'
                ],

                modules: {
                    toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'align': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['image'],
                        [{ 'color': [] }, { 'background': [] }],
                    ],
                    clipboard: {
                        matchVisual: false
                    }

                },

            });
            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                const ops = delta.ops.map((op) => ({ insert: op.insert }));
                return new Delta(ops)
            })
            // Quill içeriğini güncelleme
            quill.on('text-change', () => {
         
                setEditorContent(quill.root.innerHTML);
            });

        }
    }, []);





    useEffect(() => {
        fetchBlogAll()
    }, [])

    return (
        <StyledBlogContainer>

            <StyledHeader>
                <Button onClick={() => {
                    setMOdalInfo({
                        visible: true
                    })
                }} type='primary' style={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: "5px" }} >
                    Ekle  <FaPlus />
                </Button>
            </StyledHeader>
            <StyledContent>
                <Table
                    loading={
                        loading
                    }
                    dataSource={blogData}
                    style={{
                        width: "100%"
                    }}
                    scroll={{
                        x: 600
                    }}
                    columns={[
                        {
                            title: "id",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.id}
                                    </p>
                                )

                            }
                        },
                        {
                            title: "Başlık",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.title}
                                    </p>
                                )

                            }
                        },
                        {
                            title: "İçerik",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.description?.substring(0, 70)}...
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Düzenle",
                            render: (row, record) => {
                                return (
                                    <div style={{ display: "flex", columnGap: "10px" }} >
                                        <Button type='primary' onClick={() => {
                                            setMOdalInfo({
                                                visible: true,
                                                editMode: true
                                            })
                                            setAddBlogData({
                                                title: record.title,
                                                description: record?.description,
                                                id: record.id
                                            })
                                            setFileInfo({
                                                file: record?.imageUrl,
                                                name: record?.imageUrl
                                            })
                                            setBlogImg(record?.imageUrl)
                                        }}  >
                                            <FaEdit />
                                        </Button>
                                        <Button danger type='primary' onClick={async () => {

                                            Modal.confirm({
                                                title: "Silmek istediğinize emin misiniz ?",
                                                okText: "Eminim",
                                                cancelText: "iptal",
                                                onOk: async () => {
                                                    try {
                                                        await axiosInstance.delete(`/blogs/delete/${record?.id}`)

                                                        fetchBlogAll()

                                                        toast.success("Silme işlemi tamamlandı", {
                                                            position: "top-center",
                                                            autoClose: 2000
                                                        })
                                                    }
                                                    catch (err) {

                                                    }
                                                }
                                            })



                                        }}  >
                                            <FaTrash />
                                        </Button>
                                    </div>
                                )

                            }
                        }

                    ]}
                />

            </StyledContent>
            <StyledModal

                open={modalInfo.visible}
                title={modalInfo.editMode ? "Güncelle" : "Ekle"}
                onCancel={() => {
                    setMOdalInfo({
                        visible: false,
                        editMode: false
                    })
                    setAddBlogData({
                        title: "",
                        description: ""
                    })
                }}
                footer={() => {
                    return (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                            <Button onClick={() => {
                                blogRef.current.click()
                            }} type='primary' style={{ display: "flex", alignItems: "center", columnGap: "5px" }} >
                                Fotoğraf <FaFile />
                            </Button>
                            <input type="file" style={{ display: "none" }} ref={blogRef} onChange={handleFileChange} />
                            <div style={{ display: "flex", alignItems: "center", columnGap: "5px" }} >
                                <Button danger type='primary' onClick={() => {
                                    setMOdalInfo({
                                        visible: false,
                                        editMode: false
                                    })
                                    setAddBlogData({
                                        title: "",
                                        description: ""
                                    })
                                    setFileInfo({
                                        name: "",
                                        file: null
                                    })
                                    setBlogImg()
                                }}  >
                                    İptal
                                </Button>
                                <Button loading={loading} onClick={() => {
                                    if (modalInfo.editMode) {
                                        fetchUpdateBlog()
                                    }
                                    else {
                                        fetchAddBlog()
                                    }


                                }} type='primary' disabled={addBlogData.description == "" || addBlogData.title == "" || !blogImg}  >
                                    Kaydet
                                </Button>
                            </div>
                        </div>
                    )
                }}
            >

                <StyledMOdalContent>


                    <Input placeholder='Başlık' value={addBlogData.title} onChange={(e) => {
                        setAddBlogData({
                            ...addBlogData,
                            title: e.target.value
                        })
                    }} />
                  
                    <Input.TextArea value={addBlogData.description} onChange={(e) => {
                        setAddBlogData({
                            ...addBlogData,
                            description: e.target.value
                        })
                    }} placeholder='İçerik' style={{ resize: "none" }} rows={10} />

                    {blogImg ? (
                        <BlogImgModal style={{ cursor: "pointer" }} onClick={(e) => {
                            PreviewTopicImg()
                        }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
                                <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }} >
                                    <FaImage style={{ fontSize: "16px" }} />
                                    {fileInfo.name?.substring(0, 30)}...
                                </div>
                                <Button danger type='primary' size='small' style={{ display: "flex", alignItems: "center" }} onClick={(e) => {
                                    e.stopPropagation()
                                    setBlogImg(null)
                                    setFileInfo({
                                        name: "",
                                        file: null
                                    })
                                }} >
                                    <FaTrash />
                                </Button>
                            </div>
                        </BlogImgModal>
                    ) : (
                        <BlogImgModal>
                            Henüz dosya seçilmedi
                        </BlogImgModal>

                    )}
                </StyledMOdalContent>


            </StyledModal>
        </StyledBlogContainer>
    )
}

export default BlogDashboard

{/* <div ref={quillRef} style={{
                    minHeight: "350px"
                }} ></div> */}

const StyledBlogContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    row-gap: 20px;
`
const StyledHeader = styled.div`
    display: flex;
    padding-bottom: 10px;
    justify-content: flex-start;
    border-bottom: 1px dashed gray;
`

const StyledMOdalContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    row-gap: 15px;

`

const StyledContent = styled.div`
    display: flex;
    width: 100%;
`

const BlogImgModal = styled.div`
    display: flex;
    align-items: center;
    border: 1px dashed gray;
    border-radius: 8px;
    padding: 10px;
    column-gap: 10px;
`

const StyledModal = styled(Modal)`
    width: 800px !important;
    
`

const StyledQuillElement = styled.div`
    height: 350px;
    min-height: 350px;
`
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { CourseCalendarGetById, deleteCourseApi, deleteCourseSendEmailApi, getDetailCourseApi } from '../../../../api/Course'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PropagateLoader } from 'react-spinners'
import EditCourseInfo from '../EditCourseInfo/EditCourseInfo'
import EditCourseStudentTab from '../EditCourseStudentTab'
import EditCourseProgram from '../EditCourseProgram/EditCourseProgram'
import CourseUserAbsencesDashboard from '../CourseUserAbsencesDashboard/CourseUserAbsencesDashboard'
import AddOfflineCourseContent from '../../OfflineCourse/CreateContentOfflineCourse/AddOfflineCourseContent'
import SelectTeacherTab from '../SelectTeacherTab'
import EditOnlineCourseContent from '../EditOnlineCourseContent/EditOnlineCourseContent'
import styled from 'styled-components'
import UpdateCourseCertificate from '../Certificate/UpdateCourseCertificate'
import OnlineCoursePreviewContent from '../OnlineCoursePreviewContent/OnlineCoursePreviewContent'
import CourseImages from '../CourseImages/CourseImages'


export const UpdateCourseContext = createContext()

const UpdateCourseForm = ({ setTitle }) => {


    const [mainData, setMainData] = useState()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(1);
    const navigation = useNavigate()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteConfirmSection, setShowDeleteConfirmSection] = useState(false)
    const [disableButtonControl, setDisableButtonControl] = useState({
        modalButtonDisable: false,
        deleteButtonDisable: true
    })


    const detailCourseApiRequest = async () => {
        try {
            setLoading(true)
            const response = await getDetailCourseApi(id ?? "")
 
            setMainData(response.data)
            setTitle(response.data.name)

        }
        catch (err) {
            navigation(-1)
            toast.error(err.response?.data.message, {
                autoClose: 1500
            })
        }
        finally {
            setLoading(false)
        }

    }

    const typeControl = useMemo(() => {
        let result = mainData?.type == "Offline"
        return result
    }, [mainData])



    useEffect(() => {
        detailCourseApiRequest()
    }, [])



    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }} >
                <PropagateLoader color="#36d7b7" />
            </div>
        )
    }



    return (
        <>
            <UpdateCourseContext.Provider value={{
                detailCourseApiRequest: detailCourseApiRequest,
                mainData: mainData
            }} >
                <StyledNav tabs>
                    <StyledNavItem active={true} >
                        <NavLink
                            style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}
                            /* className={`${activeTab == 1 && "active"}`} */
                            onClick={() => {
                                setActiveTab(1)
                            }}
                        >
                            Bilgiler
                        </NavLink>
                    </StyledNavItem>
                    {
                        typeControl && (
                            <NavItem>
                                <NavLink
                                    style={activeTab == 2 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}
                                    onClick={() => {
                                        setActiveTab(2)
                                    }}
                                >
                                    İçerik
                                </NavLink>
                            </NavItem>
                        )
                    }
                    <StyledNavItem>
                        <NavLink
                            style={activeTab == 4 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                cursor: "pointer"
                            }}
                            className={`${activeTab == 4 && "active"}`}
                            onClick={() => {
                                setActiveTab(4)
                            }}
                        >
                            Öğrenciler
                        </NavLink>
                    </StyledNavItem>
                    {
                        !typeControl && (
                            <StyledNavItem>
                                <NavLink
                                    style={activeTab == 5 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                        cursor: "pointer"
                                    }}
                                    /*  className={`${activeTab == 5 && "active"}`} */
                                    onClick={() => {
                                        setActiveTab(5)
                                    }}
                                >
                                    Kurs Programı
                                </NavLink>
                            </StyledNavItem>
                        )
                    }
                    {
                        !typeControl && (
                            <StyledNavItem>
                                <NavLink
                                    style={activeTab == 6 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                        cursor: "pointer"
                                    }}
                                    className={`${activeTab == 6 && "active"}`}
                                    onClick={() => {
                                        setActiveTab(6)
                                    }}
                                >
                                    Devam Durumu
                                </NavLink>
                            </StyledNavItem>
                        )
                    }


                    <StyledNavItem>
                        <NavLink
                            style={activeTab == 7 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                cursor: "pointer"
                            }}
                            className={`${activeTab == 7 && "active"}`}
                            onClick={() => {
                                setActiveTab(7)
                            }}
                        >
                            Eğitmen
                        </NavLink>
                    </StyledNavItem>
                    {
                        !typeControl && (
                            <StyledNavItem>
                                <NavLink
                                    style={activeTab == 8 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                        cursor: "pointer"
                                    }}
                                    className={`${activeTab == 8 && "active"}`}
                                    onClick={() => {
                                        setActiveTab(8)
                                    }}
                                >
                                    Canlı Eğitim Kurs İçeriği
                                </NavLink>
                            </StyledNavItem>

                        )
                    }
                    {
                        !typeControl && (
                            <StyledNavItem>
                                <NavLink
                                    style={activeTab == 10 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                        cursor: "pointer"
                                    }}
                                    className={`${activeTab == 10 && "active"}`}
                                    onClick={() => {
                                        setActiveTab(10)
                                    }}
                                >
                                    Kurs İçeriği
                                </NavLink>
                            </StyledNavItem>
                        )

                    }
                    <StyledNavItem>
                        <NavLink
                            style={activeTab == 9 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                cursor: "pointer"
                            }}
                            className={`${activeTab == 9 && "active"}`}
                            onClick={() => {
                                setActiveTab(9)
                            }}
                        >
                            Sertifika
                        </NavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <NavLink
                            style={activeTab == 11 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                                cursor: "pointer"
                            }}
                            className={`${activeTab == 11 && "active"}`}
                            onClick={() => {
                                setActiveTab(11)
                            }}
                        >
                            Fotoğraflar
                        </NavLink>
                    </StyledNavItem>
                </StyledNav>
                {
                    activeTab == 1 && (
                        <StyledTabContainer>
                            <EditCourseInfo data={mainData} />
                        </StyledTabContainer>

                    )
                }
                {
                    activeTab == 2 && (
                        <StyledTabContainer>
                            <AddOfflineCourseContent />
                        </StyledTabContainer>

                    )
                }
               {/*  {
                    activeTab == 3 && (
                        <StyledTabContainer>
                            <AddOfflineCourseContent />
                        </StyledTabContainer>
                    )
                } */}
                {
                    activeTab == 4 && (
                        <StyledTabContainer>
                            <EditCourseStudentTab userList={mainData} />
                        </StyledTabContainer>
                    )
                }
                {
                    activeTab == 5 && !typeControl && (
                        <StyledTabContainer>

                            <EditCourseProgram mainData={mainData} />
                        </StyledTabContainer>
                    )
                }
                {
                    activeTab == 6 && !typeControl && (
                        <StyledTabContainer>
                            <CourseUserAbsencesDashboard />

                        </StyledTabContainer>
                    )
                }
                {
                    activeTab == 7 && (
                        <StyledTabContainer>
                            <SelectTeacherTab mainData={mainData} apiRequest={detailCourseApiRequest} />

                        </StyledTabContainer>
                    )
                }
                {
                    activeTab == 8 && (
                        <StyledTabContainer>
                            <EditOnlineCourseContent apiRequest={detailCourseApiRequest} />
                        </StyledTabContainer>
                    )
                }{
                    activeTab == 9 && (
                        <StyledTabContainer>

                            <UpdateCourseCertificate />
                        </StyledTabContainer>
                    )
                }
                {
                    activeTab == 10 && (
                        <StyledTabContainer>

                            <OnlineCoursePreviewContent />
                        </StyledTabContainer>
                    )
                }
                {
                    activeTab == 11 && (
                        <StyledTabContainer>
                            <CourseImages />
                        </StyledTabContainer>
                    )
                }
                {/*    <TabContent activeTab={activeTab} style={{ paddingTop: "20px" }} className="tab_content" >
                    <TabPane tabId={1} >
                        <EditCourseInfo data={mainData} />
                    </TabPane>
                    {
                        typeControl && (
                            <TabPane tabId={2}>
                                <AddOfflineCourseContent />
                            </TabPane>
                        )
                    }

                    <TabPane tabId={4}>
                        <EditCourseStudentTab userList={mainData} />
                    </TabPane>
                    {
                        !typeControl && (
                            <TabPane tabId={5}>
                                <EditCourseProgram mainData={mainData} />
                            </TabPane>
                        )
                    }
                    {
                        !typeControl && (
                            <TabPane tabId={6}   >
                                <CourseUserAbsencesDashboard />
                            </TabPane>
                        )
                    }
                    <TabPane tabId={7}  >
                        <SelectTeacherTab mainData={mainData} apiRequest={detailCourseApiRequest} />
                    </TabPane>
                    {
                        !typeControl && (
                            <TabPane tabId={8} >
                                <EditOnlineCourseContent apiRequest={detailCourseApiRequest} />
                            </TabPane>
                        )
                    }
                    <TabPane tabId={9}  >
                        <UpdateCourseCertificate />
                    </TabPane>
                </TabContent> */}

                <Modal isOpen={showDeleteModal}>
                    <ModalHeader>
                        Onay Menüsü
                    </ModalHeader>
                    <ModalBody>
                        Bu kursu silmek istediğinizden emin misiniz ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={async () => {
                            try {
                                await deleteCourseSendEmailApi(id)

                                setShowDeleteConfirmSection(true)
                                setShowDeleteModal(false)
                                setDisableButtonControl({
                                    ...disableButtonControl,
                                    modalButtonDisable: true
                                })
                            }
                            catch (err) {
                                setShowDeleteModal(false)
                                toast.error(err.message, {
                                    autoClose: 1000
                                })
                            }

                        }}


                        >
                            Onayla
                        </Button>
                        <Button color="primary" onClick={() => {
                            setShowDeleteModal(false)
                        }} >
                            İptal
                        </Button>

                    </ModalFooter>
                </Modal>

            </UpdateCourseContext.Provider>


        </>
    )
}

const StyledTabContainer = styled.div`
    margin-top: 20px;
`


const StyledNav = styled(Nav)`
    width: 100%;
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    /* Firefox için */
    scrollbar-width: none;

    /* Diğer tarayıcılar için */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    
    /* Edge ve IE 11 için */
    & {
        -ms-overflow-style: none; 
    }
`
const StyledNavItem = styled(NavItem)`
    white-space: nowrap;
`


export default UpdateCourseForm
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseURL = "https://api-kuzem.konya.bel.tr/api";
/* const baseURL = "http://192.168.146.57:50002/api" */

const axiosInstance = axios.create({
    baseURL,
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken) {
                try {
                    const { data } = await axios.post(`${baseURL}/auth/CreateAccessTokenWithRefreshToken`, refreshToken, {
                        headers: {
                            "Accept": "*/*",
                            "Content-Type": "application/json-patch+json"
                        }
                    })

                    localStorage.setItem("authToken", data.accessToken.token);
                    localStorage.setItem("refreshToken", `"${data.refreshToken.token}"`);

                    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken.token}`;
                    originalRequest.headers["Authorization"] = `Bearer ${data.accessToken.token}`;

                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("refreshToken");
                    window.location.href = "/giris";
                    return Promise.reject(refreshError);
                }
            }

            localStorage.removeItem("authToken");
            localStorage.removeItem("refreshToken");
            window.location.href = "/giris";
            return;
        }
        try {

            const message = error?.response?.data?.Detail;

            if (message == "") {
                let validErrorMessage = error?.response?.data?.Errors[0]["ErrorMessage"];
                toast.error(validErrorMessage, {
                    autoClose: 1500,
                    position: "top-center"
                })
            }
            else {
                toast.error(message ?? "Bir şeyler yanlış gitti. tekrar deneyiniz.", {
                    autoClose: 1500,
                    position: "top-center"
                })
            }
        } catch (err) {

            toast.error("Bir şeyler yanlış gitti. Lütfen tekrar deneyin.", {
                autoClose: 1500
            });
        }
        return Promise.reject(error)

    }
);

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);

export { axiosInstance, fetcher };

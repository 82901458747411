import React, { useEffect, useMemo, useState } from 'react'
import "./surveyListDashboard.scss"
import DataTable from 'react-data-table-component'
import { Button, Card, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { addSurveyAnswersApi, createSurveyQuestionApi, deleteSurveyAnswerApi, deleteSurveyQuestionApi, surveyGetAllForResultApi, surveyQuestionUpdateApi, surveysGetAll, updateSurveyAnswerApi, updateSurveyApi } from '../../../../api/Survey'
import { BsPlus, BsQuestion, BsTrash } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Pagination, Switch, Button as AntButton, Table as AntTable, Input as AntInput } from 'antd'
import { TbPencilQuestion, TbTrash } from 'react-icons/tb'
import { v4 as uuid } from "uuid"
import { FaCheck } from 'react-icons/fa'
import CreateQuestionModal from '../CreateSurvey/CreateQuestionModal'
import SurveyResultModal from './SurveyResultModal'
import styled from 'styled-components'

const SurveyListDashboard = () => {
    const [dataList, setDataList] = useState([])
    const [selectedQuestionContent, setSelectedQuestionContent] = useState("")
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [updateModal, setUpdateModal] = useState({
        visible: false,
        updateData: {
            name: "",
            description: "",
            courseName: "",
            id: "",
            courseId: "",
            status: false,
            surveyQuestions: []
        }
    })
    const [updateQuestionModal, setUpdateQuestionModal] = useState({
        visible: false,
        data: null
    })
    const [answerPageIndex, setAnswerPageIndex] = useState(1)
    const [deletedAnswers, setDeletedAnswers] = useState([])

    const [answerList, setAnswerList] = useState([])
    const [surveyId, setSurveyId] = useState()
    const [createQuestionModal, setCreateQuestionModal] = useState({
        editMode: false,
        questionId: "",
        visible: false,
        questionContent: ""
    })

    const [resultModalData, setResultModalData] = useState({
        visible: false,
        data: null
    })

    const StyledModalHeader = styled(ModalHeader)`
    padding: 15px 12px;
        h5{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 0;
        }
        
    `


    const addNewQuestion = async () => {
        try {
            const formatAnswerList = answerList.map((el, index) => {
                return {
                    ...el,
                    option: rakamToHarf(index + 1)
                }
            })

            const response = await createSurveyQuestionApi({
                surveyId: surveyId,
                question: createQuestionModal.questionContent
            })
            await Promise.all(formatAnswerList.map(x => addSurveyAnswersApi({
                surveyQuestionId: response.data.id,
                answer: x.answerContent,
                option: x.option
            })))

            setCreateQuestionModal({
                editMode: false,
                questionId: "",
                visible: false,
                questionContent: ""
            })
            setAnswerList([])
            setSurveyId()
            const newList = await getSurveyList()

            setUpdateQuestionModal({
                visible: true,
                data: newList.find(el => el.id == updateQuestionModal.data.id)
            })

            toast.success("Yeni Soru Eklendi", {
                autoClose: 1200
            })

        }
        catch (err) {

        }
    }




    function rakamToHarf(rakam) {
        const asciiOffset = 65;
        const harf = String.fromCharCode(asciiOffset + parseInt(rakam) - 1);
        return harf;
    }

    const getSurveyList = async () => {
        try {
            const response = await surveysGetAll({
                page: 0,
                pageSize: 500
            })
            
            setDataList(response.data.items.reverse())


            setSelectedQuestionContent()

            return response.data.items
        }
        catch (err) {

        }
    }

    const disableUpdateBtn = useMemo(() => {
        return updateModal.updateData?.name == "" || updateModal.updateData.description == ""
    }, [updateModal])


    const updateSurveyProcess = async () => {
        try {
            const { updateData } = updateModal
            const response = await updateSurveyApi({
                id: updateData.id,
                name: updateData.name,
                courseId: updateData.courseId,
                description: updateData.description,
                status: updateData.status
            })
            setDataList(dataList.map(el => {
                if (el.id == updateData.id) {
                    return {
                        ...el,
                        name: updateData.name,
                        description: updateData.description
                    }
                }
                return el
            }))
            setUpdateModal({
                visible: false,
                updateData: {
                    courseId: "",
                    courseName: "",
                    description: "",
                    id: "",
                    name: "",
                    status: false,
                    surveyQuestions: []
                }
            })
            toast.success("Güncelleme Başarılı", {
                autoClose: 1500
            })
        }
        catch (err) {

        }
    }

    const deleteQuestionAnswer = async (data) => {
        try {
   


            let questLen = updateQuestionModal?.data?.surveyQuestions[answerPageIndex - 1]?.surveyAnswers?.length

            if (questLen == 2) {
                toast.error("En az iki adet seçenek olmalı", {
                    autoClose: 2000
                })
                return
            }


            setUpdateQuestionModal({
                visible: true,
                data: {
                    ...updateQuestionModal.data,
                    surveyQuestions: updateQuestionModal.data.surveyQuestions.map((el, index) => {
                        if (index == answerPageIndex - 1) {
                            return {
                                ...el,
                                surveyAnswers: el?.surveyAnswers.filter(x => x.id !== data.id)
                            }
                        }
                        return el
                    })
                }
            })
            setDeletedAnswers([
                ...deletedAnswers,
                data
            ])
        }
        catch (err) {

        }
    }

    function isSortedByOption(arr) {
        const length = arr.length;
        const sortedArr = [...arr].sort((a, b) => a.option.localeCompare(b.option));
        if (sortedArr[0].option !== 'A') {
            return false;
        }
        for (let i = 0; i < length; i++) {
            if (sortedArr[i].option.charCodeAt(0) !== 'A'.charCodeAt(0) + i) {
                return false;
            }
        }
        return true;
    }
    const updateSurveyQuestionAnswer = async (data) => {
        try {
            const invalidLetterList = ["A", "B", "C", "D", "E"]
            let controlLetter = updateQuestionModal.data.surveyQuestions[answerPageIndex - 1]["surveyAnswers"]
            let control = true
            for (let i of controlLetter) {
                if (!invalidLetterList.includes(i.option)) {
                    control = false
                    toast.warning("A,B,C,D,E harflerinden biri seçilmeli", {
                        autoClose: 1200
                    })
                    return
                }
            }
            let control2 = isSortedByOption(controlLetter)
            if (!control2) {
                toast.warning("Seçenekler A,B,C,D,E şeklinde sıralı olmalı", {
                    autoClose: 1500
                })
                return;
            }



            if (control) {
                const resultDeleted = deletedAnswers.filter(el => el?.real !== false)

               
                const newAnswers = updateQuestionModal.data.surveyQuestions[answerPageIndex - 1]["surveyAnswers"]?.filter(el => el?.real == false)
                const oldAnswers = updateQuestionModal.data.surveyQuestions[answerPageIndex - 1]["surveyAnswers"]?.filter(el => el?.real == undefined)
            

                if (newAnswers?.some(el => el.answer == "") || oldAnswers?.some(el => el.answer == "")) {
                    toast.error("seçenek boş bırakılamaz", {
                        autoClose: 2000
                    })
                    return;
                }
                for (let item of resultDeleted) {
                    try {
                        await deleteSurveyAnswerApi(item.id)
                    }
                    catch (err) {

                    }
                }
                for (let el of oldAnswers) {
                    try {
                        await updateSurveyAnswerApi({
                            id: el.id,
                            surveyQuestionId: el.surveyQuestionId,
                            option: el.option,
                            answer: el.answer
                        })
                    }
                    catch (err) {

                    }
                }
                for (let el of newAnswers) {
                    try {
                        await addSurveyAnswersApi({
                            surveyQuestionId: el.surveyQuestionId,
                            answer: el.answer,
                            option: el.option
                        })
                    }
                    catch (err) {

                    }

                }
                /* await Promise.all(resultDeleted.map(el => deleteSurveyAnswerApi(el.id))) */
                /* await Promise.all(oldAnswers.map(el => updateSurveyAnswerApi({
                    id: el.id,
                    surveyQuestionId: el.surveyQuestionId,
                    option: el.option,
                    answer: el.answer
                }))) */

                /* await Promise.all(newAnswers.map(el => addSurveyAnswersApi({
                    surveyQuestionId: el.surveyQuestionId,
                    answer: el.answer,
                    option: el.option
                }))) */

                let selectedUpdataQuest = updateQuestionModal.data.surveyQuestions[answerPageIndex - 1]

       

                await surveyQuestionUpdateApi({
                    id: selectedUpdataQuest?.id,
                    surveyId: selectedUpdataQuest?.surveyId,
                    question: selectedUpdataQuest?.question
                })

                const allList = await getSurveyList()
                let selected = allList.find(x => x.id == updateQuestionModal.data.id)
                setUpdateQuestionModal({
                    visible: true,
                    data: selected
                })
                toast.success("Güncellendi", {
                    autoClose: 1200
                })
                setDeletedAnswers([])
            }

        }
        catch (err) {
        
        }
    }


    const addSurveyAnswerDisableControl = useMemo(() => {
        return updateQuestionModal.data?.surveyQuestions[answerPageIndex - 1]?.surveyAnswers?.length >= 5
    }, [updateQuestionModal, answerPageIndex])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm()
    };


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <AntInput
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) => {
          
            return record[dataIndex]
                ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase())
                : ''
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    useEffect(() => {
        getSurveyList()
    }, [])


    return (
        <>
            <div className='survey_list_dashboard_container' >
                <AntTable
                    size='middle'
                    scroll={{
                        x: 800
                    }}
                    dataSource={dataList}
                    pagination={{
                        pageSize: 10,
                    }}
                    columns={[{
                        title: "Anket İsmi",
                        render: (row, record) => {
                            return (
                                <span> {record?.name} </span>
                            )
                        },
                        dataIndex: "name",
                        ...getColumnSearchProps("name")
                    },
                    {
                        title: "Kurs İsmi",
                        render: (row, record) => {
                            return (
                                <span>
                                    {record.courseName}
                                </span>
                            )
                        },
                        dataIndex: "courseName",
                        ...getColumnSearchProps("courseName")
                    },
                    {
                        title: "Düzenle",
                        render: (row, record) => {
                            return (
                                <div style={{ display: "flex" }}>

                                    <div>
                                        <Button color='warning' style={{ marginRight: "15px" }} onClick={() => {
                                       
                                            setUpdateModal({
                                                visible: true,
                                                updateData: row
                                            })
                                        }} >
                                            <FiEdit />
                                        </Button>
                                    </div>

                                    <div>
                                        <Button onClick={() => {
                                            setSurveyId(row.id)
                                            setUpdateQuestionModal({
                                                visible: true,
                                                data: row
                                            })
                                        }} >
                                            <TbPencilQuestion style={{ fontSize: "18px" }} />

                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        title: "Sonuçlar",
                        render: (row, record) => {
                            return (
                                <AntButton type='primary' onClick={async () => {
                                    try {
                                        const response = await surveyGetAllForResultApi(record.id)
                                        setResultModalData({
                                            visible: true,
                                            data: response.data.items,
                                            surveyName: record.name
                                        })
                                    }
                                    catch (err) {

                                    }
                                }} >
                                    Sonuç
                                </AntButton>
                            )
                        }
                    },
                    {
                        title: "Aktif",
                        render: (row, record) => {
                            return (
                                <Switch defaultValue={row.status} onChange={async (e) => {
                                    try {
                                        const response = await updateSurveyApi({
                                            id: row.id,
                                            courseId: row.courseId,
                                            description: row.description,
                                            name: row.name,
                                            status: e
                                        })
                                        await getSurveyList()

                                        if (e) {
                                            toast.success("Anket aktif hale getirildi", {
                                                autoClose: 1500
                                            })
                                        }
                                        else {
                                            toast.success("Anket pasif hale getirildi", {
                                                autoClose: 1500
                                            })
                                        }

                                    }
                                    catch (err) {

                                    }


                                }} />
                            )
                        }
                    }

                    ]}
                />


            </div>

            <CreateQuestionModal addQuestion={addNewQuestion} answerList={answerList} setAnswerList={setAnswerList} createQuestionModal={createQuestionModal} setCreateQuestionModal={setCreateQuestionModal} />

            <SurveyResultModal resultModalData={resultModalData} setResultModalData={setResultModalData} />

            <Modal isOpen={updateModal.visible} >
                <ModalHeader>
                    Anket Güncelle
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Input onChange={(e) => {
                            setUpdateModal({
                                ...updateModal,
                                updateData: {
                                    ...updateModal.updateData,
                                    name: e.target.value
                                }
                            })
                        }} value={updateModal.updateData?.name} type='text' style={{ width: "100%", marginLeft: 0, marginBottom: "10px" }} placeholder='İsim' />
                    </div>
                    <div>
                        <Input onChange={(e) => {
                            setUpdateModal({
                                ...updateModal,
                                updateData: {
                                    ...updateModal.updateData,
                                    description: e.target.value
                                }
                            })
                        }} value={updateModal.updateData?.description} type='textarea' rows={6} placeholder='Açıklama' style={{ width: "100%", resize: "none", marginLeft: 0 }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <AntButton type='primary' disabled={disableUpdateBtn} onClick={updateSurveyProcess}>
                        Güncelle
                    </AntButton>
                    <AntButton type='primary' danger onClick={() => {
                        setUpdateModal({
                            updateData: {
                                courseId: "",
                                courseName: "",
                                description: "",
                                id: "",
                                name: "",
                                status: false,
                                surveyQuestions: []
                            },
                            visible: false
                        })
                    }} >
                        İptal
                    </AntButton>
                </ModalFooter>
            </Modal>

            <Modal isOpen={updateQuestionModal.visible} size='lg' centered  >
                <StyledModalHeader >

                    <p>
                        {updateQuestionModal.data?.name?.length > 30 ? `${updateQuestionModal?.data?.name?.substring(0, 30)}...` : updateQuestionModal?.data?.name}
                    </p>
                    <AntButton type='primary' danger onClick={() => {
                        setUpdateQuestionModal({
                            visible: false,
                            data: null
                        })
                        setDeletedAnswers([])
                        setAnswerPageIndex(1)
                    }} >
                        İptal
                    </AntButton>

                </StyledModalHeader>
                <UpdateQuestionModalBody>
                    <Input type='textarea' style={{ marginLeft: "0", resize: "none" }} placeholder='Soru Başlığı' onChange={(e) => {
                      
                        setUpdateQuestionModal({
                            ...updateQuestionModal,
                            data: {
                                ...updateQuestionModal.data,
                                surveyQuestions: updateQuestionModal.data.surveyQuestions.map((el, index) => {
                                    if (index == answerPageIndex - 1) {
                                        return {
                                            ...el,
                                            question: e.target.value
                                        }
                                    }
                                    return el
                                })
                            }
                        })

                    }} value={updateQuestionModal.data?.surveyQuestions[answerPageIndex - 1]?.question} />
                    <div className='update_question_modal_answer_container' >
                        <div className='update_question_modal_answer_header' >
                            <h6 className='update_question_modal_answer_header_title' >
                                Seçenekler
                            </h6>
                            <Button size='sm' color='primary' disabled={addSurveyAnswerDisableControl} onClick={() => {
                            
                                const tempAddedAnswer = {
                                    answer: "",
                                    id: uuid(),
                                    real: false,
                                    surveyQuestionId: updateQuestionModal.data?.surveyQuestions[answerPageIndex - 1]?.id,
                                    surveyResults: [],
                                    option: rakamToHarf(updateQuestionModal.data?.surveyQuestions[answerPageIndex - 1]?.surveyAnswers?.length + 1)
                                }
            
                                setUpdateQuestionModal({
                                    ...updateQuestionModal,
                                    data: {
                                        ...updateQuestionModal.data,
                                        surveyQuestions: updateQuestionModal.data.surveyQuestions.map((x, index) => {
                                            if (index == answerPageIndex - 1) {
                                                return {
                                                    ...x,
                                                    surveyAnswers: [
                                                        ...x.surveyAnswers,
                                                        tempAddedAnswer
                                                    ]
                                                }
                                            }
                                            return x
                                        })
                                    }
                                })
                            }}  >
                                Seçenek ekle
                            </Button>
                        </div>

                        {
                            updateQuestionModal?.data?.surveyQuestions[answerPageIndex - 1]?.surveyAnswers?.map((el) => {

                                return (
                                    <div className='update_question_modal_survey_item' >
                                        <Input className='opt_item' value={el.option} onChange={(e) => {
                                         

                                            setUpdateQuestionModal({
                                                ...updateQuestionModal,
                                                data: {
                                                    ...updateQuestionModal.data,
                                                    surveyQuestions: updateQuestionModal.data.surveyQuestions?.map((x, index) => {
                                                        if (index == answerPageIndex - 1) {
                                                            return {
                                                                ...x,
                                                                surveyAnswers: x.surveyAnswers?.map(item => {
                                                                    if (item.id == el.id) {
                                                                        return {
                                                                            ...item,
                                                                            option: e.target.value.toUpperCase()
                                                                        }
                                                                    }
                                                                    return item
                                                                })
                                                            }
                                                        }
                                                        return x
                                                    })
                                                }
                                            })

                                        }} />
                                        <Input type='textarea' className='opt_content_item' value={el.answer} onChange={(e) => {

                                            setUpdateQuestionModal({
                                                ...updateQuestionModal,
                                                data: {
                                                    ...updateQuestionModal.data,
                                                    surveyQuestions: updateQuestionModal.data.surveyQuestions?.map((x, index) => {
                                                        if (index == answerPageIndex - 1) {
                                                            return {
                                                                ...x,
                                                                surveyAnswers: x.surveyAnswers?.map(item => {
                                                                    if (item.id == el.id) {
                                                                        return {
                                                                            ...item,
                                                                            answer: e.target.value
                                                                        }
                                                                    }
                                                                    return item
                                                                })
                                                            }
                                                        }
                                                        return x
                                                    })
                                                }
                                            })
                                        }} />

                                        <AntButton danger type='primary' onClick={() => {
                                            deleteQuestionAnswer(el)


                                        }} >
                                            <TbTrash />
                                        </AntButton>
                                    </div>
                                )
                            })
                        }
                    </div>
                </UpdateQuestionModalBody>
                <ModalFooter>
                    <div className='update_question_modal_footer' >
                        <QuestionModalFooterBody>
                            <AntButton type='primary' onClick={(e) => {
                                updateSurveyQuestionAnswer()

                            }} >
                                {answerPageIndex}. soruyu güncelle
                            </AntButton>
                            <AntButton style={{ backgroundColor: "#088F7D", color: "white" }} color='primary' onClick={() => {
                                
                                setCreateQuestionModal({
                                    ...createQuestionModal,
                                    visible: true
                                })
                            }} >
                                Soru Ekle
                            </AntButton>

                        </QuestionModalFooterBody>
                        <Pagination pageSize={1} total={updateQuestionModal.data?.surveyQuestions?.length} simple onChange={(e) => {
                           
                            setDeletedAnswers([])
                            setAnswerPageIndex(e)
                        }} />

                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default SurveyListDashboard


const UpdateQuestionModalBody = styled(ModalBody)`
    padding: 10px;
`

const QuestionModalFooterBody = styled.div`
    display: flex;
    column-gap: 10px;

    @media screen and (max-width:500px) {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 10px;
    }
`
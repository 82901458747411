import React, { useEffect, useMemo, useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import { GetCalendarForAllRoles } from '../../api/Course'
import { Col, Row } from 'reactstrap'
import { Button as AntButton, Select as AntSelect, Table as AntTable } from "antd"
import styled from 'styled-components'
import { MdRefresh } from 'react-icons/md'
import { format } from "date-fns"
import trLocale from "date-fns/locale/tr"
import { FaFileArchive } from 'react-icons/fa'
import { compareWithCurrentTime } from '../../utils/utils'
import EditOnlineContentModal from '../../Components/Course/UpdateCourse/EditOnlineCourseContent/EditOnlineContentModal'

const StudentOnlineMeeting = () => {

    const [loading, setLoading] = useState(false)
    const [mainData, setMainData] = useState([])
    const [activeType, setActiveType] = useState(2)
    const [contentModal, setContentModal] = useState({
        visible: false,
        data: null
    })

    const openJoinUrl = (url) => {
        window.open(url, "_blank")
    }

    const getTeacherCourse = async () => {
        try {
            setLoading(true)
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 10
            })
            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: response.data.count
            })
            let data = responsev2.data.items
            setMainData(data?.filter(el => el.type == "Online"))
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }

    }

    function filtreleIleriTarihler(courses, status = 0) {
        if (status == 0) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var ileriTarihler = courses.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                return kursBaslangicTarihi > suankiTarih; // İleri tarihlerde olanları filtrele
            });
            ileriTarihler.sort(function (a, b) {
                return new Date(a.startTime) - new Date(b.startTime);
            });
            return ileriTarihler;  //bekleyenler
        }
        else if (status == 1) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var gecmisTarihler = courses.filter(function (course) {
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBitisTarihi < suankiTarih; // Geçmiş tarihlerde olanları filtrele
            });
            gecmisTarihler.sort(function (a, b) {
                return new Date(b.startTime) - new Date(a.startTime);
            });
            return gecmisTarihler;
        }
        else {
            var suankiTarih = new Date();
            var simdikiZamandakiKurslar = courses.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBaslangicTarihi < suankiTarih && suankiTarih < kursBitisTarihi; // Şu anki zamanın aralıkta olup olmadığını kontrol et
            });
            simdikiZamandakiKurslar.sort(function (a, b) {
                return Math.abs(new Date(a.startTime) - suankiTarih) - Math.abs(new Date(b.startTime) - suankiTarih);
            });
            return simdikiZamandakiKurslar;

        }
    }

    const tempData = useMemo(() => {
        let test = mainData?.map(item => {
            if (item?.courseCalendars?.length !== 0) {
                return item?.courseCalendars?.map(el => {
                    const { courseCalendars, ...restElements } = item
                    const { onlineCourseUrls, ...rest } = el
                    return {
                        ...restElements,
                        ...rest,
                        onlineCourseUrls: onlineCourseUrls[0]
                    }
                })
            }

        }).flatMap(x => x).filter(x => x != undefined)

        if (activeType == 0) {
            const ileriTarih = filtreleIleriTarihler(test, 0)
            return ileriTarih
        }
        else if (activeType == 1) {
            const gecmis = filtreleIleriTarihler(test, 1)
            return gecmis
        }
        else {
            const active = filtreleIleriTarihler(test, 2)

            return active
        }
    }, [mainData, activeType])





    const joinLink = (row) => {
        if (activeType == 0) {
            return <AntButton disabled style={{ backgroundColor: "#FF9800", border: "none", color: "white", pointerEvents: "none", fontSize: "12px" }}  >
                Zaman Bekleniyor
            </AntButton>
        }
        else if (activeType == 1) {
            return <AntButton danger type='primary' style={{ pointerEvents: "none" }} >
                Link Aktif Değil
            </AntButton>
        }
        else {
            if (row?.onlineCourseUrls?.length == 0 || row?.onlineCourseUrls == undefined || row?.onlineCourseUrls?.meetingUrl == "Zaman Bekleniyor.") {
                return (
                    <AntButton type='primary' style={{ backgroundColor: "#FF9800", border: "none", color: "white", pointerEvents: "none", fontSize: "12px" }}  >
                        Link henüz oluşmadı ,yenileyiniz
                    </AntButton>
                )
            }
            else {
                return <AntButton type='primary' style={{ border: "none", color: "white", fontSize: "12px", cursor: "pointer" }} onClick={() => {
                    openJoinUrl(row?.onlineCourseUrls?.meetingUrl)
                }} >
                    Katıl
                </AntButton>
            }

        }
    }


    useEffect(() => {
        getTeacherCourse()
    }, [])



    const KursEklemeHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    width: 100%;
    border: 1px dashed gray;
    padding: 10px;
    border-radius: 10px;
`

    return (
        <div className='page-content'>
            <TablePageContainer title='' visible={false} header={"Canlı Ders"} >
                <StyledContainer>
                    <Row>

                        <KursEklemeHeader   >
                            <StyledAntSelect
                                value={activeType}
                                onChange={(e) => {
                                    setActiveType(e)
                                }}
                                options={[
                                    { value: 0, label: 'Bekleyen' },
                                    { value: 1, label: 'Geçmiş' },
                                    { value: 2, label: 'Aktif' },

                                ]}
                            />


                            <AntButton type='primary' style={{ display: "flex", alignItems: "center", columnGap: 10 }} onClick={() => {


                                getTeacherCourse()


                            }} >
                                Yenile<MdRefresh style={{ fontSize: "18px" }} />
                            </AntButton>


                        </KursEklemeHeader>

                        <Col lg={12} >
                            <StyledDataTableContainer>
                                <AntTable
                                    loading={loading}
                                    dataSource={tempData}
                                    scroll={{
                                        x: 800
                                    }}
                                    size='middle'
                                    pagination={{
                                        pageSize: 10
                                    }}
                                    columns={[
                                        {
                                            title: "Kurs Id",
                                            render: (row, record) => {
                                                return (
                                                    <p>
                                                        {record?.courseId}
                                                    </p>
                                                )
                                            }
                                        },
                                        {
                                            title: "İsim",
                                            render: (row, record) => {
                                                return (
                                                    <span>
                                                        {record.name.length > 20 ? `${record.name.substring(0, 20)}...` : record.name}
                                                    </span>
                                                )
                                            }
                                        },
                                        {
                                            title: "Başlangıç",
                                            render: (row, record) => {
                                                return (
                                                    <p>
                                                        <span>
                                                            {format(new Date(record.startTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })}
                                                        </span>
                                                    </p>
                                                )
                                            },
                                            sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime)
                                        },
                                        {
                                            title: "Kurs Süresi",
                                            render: (row, record) => {
                                                return (
                                                    <span>
                                                        {row.duration} (Dk)
                                                    </span>
                                                )
                                            },
                                            sorter: (a, b) => a?.duration - b?.duration
                                        },
                                        {
                                            title: "Kurs Dosyaları",
                                            render: (row, record) => {
                                                return (
                                                    <AntButton type='primary' onClick={() => {

                                                        setContentModal({
                                                            visible: true,
                                                            data: record
                                                        })
                                                    }} >
                                                        <FaFileArchive />
                                                    </AntButton>
                                                )
                                            }
                                        },
                                        {
                                            title: "Bağlantı",
                                            render: (row, record) => {

                                                return joinLink(record)
                                            }
                                        }
                                    ]}
                                />

                            </StyledDataTableContainer>

                        </Col>

                    </Row >
                    <EditOnlineContentModal apiRequest={getTeacherCourse} calendarList={mainData?.map(el => ({
                        onlineCourseFiles: el?.onlineCourseFiles,
                        id: el?.id,
                        courseId: el.courseId,
                        duration: el?.duration
                    }))} modalData={contentModal} setModalData={setContentModal} addFile={false} />
                </StyledContainer>
            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 10px 20px ;
`

const StyledDataTableContainer = styled.div`
    margin-top: 20px;
`

const StyledAntSelect = styled(AntSelect)`
    width: 200px;
    
    .ant-select-selector {
  border: 1px solid #3498db !important; /* Burada istediğiniz border rengini ve kalınlığını ayarlayabilirsiniz */
}
`


export default StudentOnlineMeeting
import React, { useEffect, useMemo, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { Button as AntButton, Input, Segmented, Table, Tooltip } from "antd"
import { getAllCourseByStatusApi } from '../../api/Course'
const CategoryStatisticModal = ({ modalData, setModalData }) => {
   
    const [tabId, setTabId] = useState("İstatistik")
    const [mainData, setMainData] = useState([])



    const StatisticData = useMemo(() => {
        let filtered = mainData.filter(el => el?.categoryId == modalData.data?.id)
        let offline = filtered.filter(el => el.type == "Offline")
        let online = filtered.filter(el => el.type == "Online")
        return [
            {
                "name": "Online",
                "data": online.length
            },
            {
                "name": "Offline",
                "data": offline.length
            }
        ]
    }, [mainData, modalData])

    const StatisticSegment = useMemo(() => {
        const columns = [
            {
                title: 'Tip',
                dataIndex: 'name',
                key: 'name',
                render: (row) => {
      
                    return (
                        <h6>
                            {row}
                        </h6>
                    )
                }
            },
            {
                title: 'Veri',
                dataIndex: 'data',
                key: 'data',
                render: (row) => {
                    return (
                        <Tooltip style={{ width: "max-content" }} placement="right" title={`
                            ${row} adet kayıt var
                        `} >
                            <span style={{ display: "inline-block", width: "50px" }} >
                                {row}
                            </span>
                        </Tooltip>

                    )
                }
            },
        ];
   
        return (
            <div >
                <Table bordered={false} dataSource={StatisticData} columns={columns} />
            </div>
        )
    }, [tabId, modalData, StatisticData])



    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters,confirm) => {
        clearFilters();
        setSearchText('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });


    const DataSegment = useMemo(() => {
        const columns = [

            {
                title: 'İsim',
                dataIndex: 'name',
                key: 'name',
                width: "40%",
                render: (row) => {
                 
                    return (
                        <h6>
                            {row}
                        </h6>
                    )
                },
                ...getColumnSearchProps('name'),
            },
            {
                title: 'Tip',
                dataIndex: 'type',
                key: 'type',
                width: "20%",
                render: (row) => {
                    return (
                        <h6>
                            {row}
                        </h6>

                    )
                }
            },
            {
                title: 'Eğitmen',
                dataIndex: 'courseAdministrator',
                key: 'courseAdministrator',
                width: "40%",
                render: (row) => {
                    return (
                        <h6>
                            {row}
                        </h6>

                    )
                }
            },
        ];

        let data = mainData.filter(el => el.categoryId == modalData?.data?.id)
        return (
            <div>
                <StyledAntTable size='small' scroll={{ x: 800 }} pagination={{
                    pageSize: 5
                }} bordered={false} dataSource={data} columns={columns} />
            </div>
        )
    }, [tabId, modalData, mainData])



    const getAllCourses = async () => {
        try {
            const tempArr = []
            const response1 = await getAllCourseByStatusApi({
                page: 0,
                pageSize: 1
            }, "Offline", false)
            const response2 = await getAllCourseByStatusApi({
                page: 0,
                pageSize: response1.data.count
            }, "Offline", false)
            tempArr.push(...response2.data.items)
            const response3 = await getAllCourseByStatusApi({
                page: 0,
                pageSize: 1
            }, "Online", false)
            const response4 = await getAllCourseByStatusApi({
                page: 0,
                pageSize: response3.data.count
            }, "Online", false)
            tempArr.push(...response4.data.items)

            setMainData(tempArr)

        }
        catch (err) {

        }
    }

    useEffect(() => {
        getAllCourses()
    }, [])


    return (
        <Modal isOpen={modalData?.visible} size='lg' >
            <StyledModalHeader>
                <h6 style={{ fontWeight: "bold", fontSize: "16px" }} >
                    {modalData?.data?.name.length > 30 ? `${modalData?.data?.name.substring(0, 30)}...` : modalData?.data?.name}
                </h6>
                <AntButton danger type='primary' onClick={() => {
                    setModalData({
                        visible: false,
                        data: null
                    })
                }} >
                    Kapat
                </AntButton>

            </StyledModalHeader>
            <ModalBody>
                <Segmented
                    block

                    options={['İstatistik', 'Veri']}
                    onChange={(value) => {
                        setTabId(value)
                    }}
                />
                <div style={{ marginTop: "15px" }} >
                    {tabId == "İstatistik" ? StatisticSegment : DataSegment}
                </div>
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    )
}

const StyledModalHeader = styled(ModalHeader)`
    h5{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
`

const StyledAntTable = styled(Table)`
    .ant-table-content{
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }
`


export default CategoryStatisticModal
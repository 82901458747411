import React, { useContext, useEffect, useState } from 'react'
import { courseContentForCourseId } from '../../api/CourseContent'
import { useParams } from "react-router-dom"
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row, UncontrolledAccordion } from "reactstrap"
import { Collapse } from 'antd';
import { UserContext } from '../../context/user';


const OfflineCourseDetailPreview = () => {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [context, dispatch] = useContext(UserContext)

    const getOfflineCoursePreview = async () => {
        try {
            const response = await courseContentForCourseId({
                page: 0,
                pageSize: 100
            }, id)
       
            setData(response.data.items)
        }
        catch (err) {

        }
    }
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };


    useEffect(() => {
        getOfflineCoursePreview()
    }, [])

    return (
        <div>
            <div className='offline_course_detail_content_header_container' >
                <h3 className='offline_course_detail_content_header' >
                    Kurs İçeriği
                </h3>
            </div>
            <div style={{ marginTop: "20px" }} >
                <UncontrolledAccordion stayOpen open={open} toggle={toggle} defaultOpen={["1", "2"]}  >
                    {
                        data?.map((el, index) => {
                            return (
                                <AccordionItem>
                                    <AccordionHeader targetId={`${index + 1}`}   >
                                        {el?.name}
                                    </AccordionHeader>
                                    <AccordionBody accordionId={`${index + 1}`}  >
                                        <div style={{ display: "flex", flexDirection: "column", rowGap: "15px" }} >
                                            {
                                                el?.courseFiles?.map(item => {
                                                    return (
                                                        <div className="offline_course_detail_preview_item_container">
                                                            <div className="offline_course_detail_preview_item">
                                                                <BsReverseLayoutTextSidebarReverse size={24} color='#088F7D' />
                                                                <p className='offline_course_detail_preview_item_description' >
                                                                    {item.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </AccordionBody>
                                </AccordionItem>
                            )
                        })
                    }
                </UncontrolledAccordion>
            </div>
        </div>
    )
}

export default OfflineCourseDetailPreview
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Input as AntInput, Button, Pagination } from "antd"
import { UserContext } from '../../../../context/user'
import { getAllCourseByStatusApi } from '../../../../api/Course'
import { PropagateLoader } from 'react-spinners'
import line from "../../../../assets/images/line-green.png"
import { useNavigate } from 'react-router-dom'
const OfflineCourseGeneral = () => {
    const [context, dispatch] = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [courseData, setCourseData] = useState([])
    const [tempData, setTempData] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const [totalCount, setTotalCount] = useState(0)
    const navigate = useNavigate()
    const [pageCount, setPageCount] = useState(1)
    const permissionRole = useMemo(() => {
        const role = context.role.replace(/\s+/g, '')
        return role == "SüperAdmin" || role == "Admin"
    }, [context])
    const [activePageIndex, setActivePageIndex] = useState(1)
    const [staticNumber, setStaticNumber] = useState(6)
    const [filters, setFilters] = useState({
        semester: "",
        year: ""
    })

    const searchElement = (text) => {
        const escapeRegex = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Özel karakterleri kaçış ile işaretler
        }
        let result = tempData
        const safeText = escapeRegex(text?.toLocaleLowerCase());
        result = result.filter(el => new RegExp(safeText).test(el.name.toLocaleLowerCase()));

        let bolum = result.length / staticNumber
        let kalan = result.length % staticNumber
        if (kalan != 0) {
            bolum += 1
        }
        setPageCount(parseInt(bolum))
        setCourseData(result)
    }

    const getCourseList = async (page, status = false) => {
        try {

            const role = context.role.replace(/\s+/g, '')
            let permControl = role == "SüperAdmin" || role == "Admin"
            setLoading(true)

            const response = await getAllCourseByStatusApi({
                page: 0,
                pageSize: staticNumber
            }, "Offline", status)
            const responsev2 = await getAllCourseByStatusApi({
                page: page ?? 0,
                pageSize: response.data.count
            }, "Offline", status)


            if (permControl) {
                setCourseData(responsev2.data.items)
                setTempData(responsev2.data.items)
                let count = response.data.count
                let bolum = count / staticNumber
                let kalan = count % staticNumber
                if (kalan !== 0) {
                    bolum += 1
                }
                setTotalCount(response.data.count)
                setPageCount(parseInt(bolum))
            }
            else {
                let ogrenciData = responsev2.data.items.filter(el => el.status == false)
                setTempData(ogrenciData)
                setCourseData(ogrenciData)
                let count = ogrenciData.length
                let bolum = count / staticNumber
                let kalan = count % staticNumber
                if (kalan !== 0) {
                    bolum += 1
                }
                setTotalCount(count)
                setPageCount(parseInt(bolum))
            }

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const courseListData = useMemo(() => {
        if (activePageIndex == 1) {
            return courseData.slice(0, staticNumber)
        }
        else {
            return courseData.slice((activePageIndex - 1) * staticNumber, activePageIndex * staticNumber)
        }
    }, [courseData, activePageIndex])


    useEffect(() => {
        getCourseList()
    }, [])

    return (
        <StyledMainCourseContainer>
            <StyledHeaderContainer>
                <StyledHeaderFilter>
                    <StyledInput
                        placeholder='Arama yap'
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value)
                            searchElement(e.target.value)
                            /*   dynamicSearch(e.target.value) */
                            setActivePageIndex(1)
                        }}
                    />
                    {
                        permissionRole && (

                            <AddCourseBtn type='primary' onClick={() => {
                                navigate(`/panel/cevrimdisi/ekle`)
                            }}>
                                Kurs Ekle
                            </AddCourseBtn>

                        )
                    }
                </StyledHeaderFilter>
            </StyledHeaderContainer>
            {
                loading ? (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }} >
                        <PropagateLoader color="#36d7b7" />
                    </div>
                ) : (
                    <ActiveCourseDashboardWrapper  >
                        {
                            courseData && courseData.length !== 0 ? (
                                <div className='active_course_container' >
                                    {
                                        courseListData.map((el, index) => {
                                
                                            return (
                                                <div key={`${index}`} className='parent-card-div' style={{ cursor: "pointer" }} onClick={() => {

                                                    if (permissionRole) {
                                                        navigate(`/panel/kurs/duzenle/${el.id}`)
                                                    }
                                                    else {
                                                        navigate(`/kurs/${el?.branch?.label}`)
                                                    }
                                                }} >
                                                    <div className='all-info-card'>
                                                        <p className='info-1'>{el.type == "Online" ? "Canlı Eğitim" : "Video Eğitim"}</p>
                                                        <p className='info-2' style={{ fontSize: el.name.length > 17 ? "15px" : "19px", textTransform: "capitalize" }}>{el.name.length > 30 ? `${el.name.substring(0, 30)}...` : el.name}

                                                        </p>
                                                        <p style={{ color: "blue", fontSize: "14px" }} >
                                                            #{el.id}
                                                        </p>
                                                        {/* <hr className='hr'/> */}
                                                        <img src={line} alt="" style={{ maxWidth: "200px" }} />
                                                        <p className='course_category'>
                                                            <span style={{ fontWeight: "bold" }} >Eğitmen : </span> {el.courseAdministrator ?? "Eğitmen Seçilmedi"}
                                                        </p>
                                                    </div>

                                                    <div className='container' id='base-card-lesson'></div>
                                                    <div className='corner-image'></div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            ) : <h3 style={{ width: "100%", textAlign: "center", marginTop: "20px", marginBottom: "20px" }} >
                                Aktif Kurs Yok
                            </h3>


                        }
                        {
                            courseData.length !== 0 && (
                                <div className='active_course_table_pagination' >
                                    <Pagination current={activePageIndex} total={pageCount} pageSize={1} onChange={(e) => {

                                        /*  getCourseList(e - 1, courseStatus)*/
                                        setActivePageIndex(e)
                                    }} />
                                </div>
                            )
                        }


                    </ActiveCourseDashboardWrapper>
                )
            }
        </StyledMainCourseContainer>
    )
}

export default OfflineCourseGeneral


const StyledMainCourseContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
`

const StyledHeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin-bottom: 20px;
    @media screen  and (max-width:500px) {
        flex-direction: column;

    }
`
const StyledHeaderFilter = styled.div`
    display: flex;
    align-items: center;    
    width: 100%;
    column-gap: 20px;
    justify-content: space-between;
    @media screen and (max-width:500px) {
        justify-content: space-between;
        align-items: center;
        /* flex-direction: column;
        padding: 10px; */
    }
`

const StyledInput = styled(AntInput)`
    height: 35px;
    width: 200px;

    @media screen and (max-width:500px)  {
        width: 200px;
    }

`

const AddCourseBtn = styled(Button)`


    @media screen  and (max-width:500px) {
            width: 150px;
           /*  margin-top: 10px; */
    }
`

const ActiveCourseDashboardWrapper = styled.div`
   display: flex;
   flex-direction: column;  
   

   /* @media screen and (max-width:600px) {
    padding: 10px;
   } */

   .active_course_container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        gap: 20px;




        @media screen and (max-width:2000px) {
            grid-template-columns: 1fr 1fr 1fr !important;
        }
        @media screen and (max-width:1200px) {
            grid-template-columns: 1fr 1fr !important;
        }
        @media screen and (max-width:570px) {
            grid-template-columns: 1fr  !important;
            /* margin-left:14%; */
            }
        @media screen and (max-width:400px) {
            /* margin-left:12%; */
            }
        @media screen and (max-width:350px) {
           /*  margin-left:8%; */
            }
        @media screen and (max-width:320px) {
        /*     margin-left:5%; */
            }

        .active_course_item {
            border: 1px solid #D1D5DB;
            border-radius: 10px;
            padding: 0;
            cursor: pointer;

            .active_course_item_content {
                padding-left: 10px;
                padding-top: 8px;

                &_title {
                    font-size: 16px;
                    color: #80949F;
                    text-transform: capitalize;
                    /*   text-transform: capitalize; */
                }

                min-height: 80px;

            }

            .active_course_item_image {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                max-width: 100%;
                min-height: 140px;
            }


            .active_course_item_footer {
                padding: 15px;
                background-color: rgba($color: gray, $alpha: .1);
                display: flex;
                justify-content: space-between;
                align-items: center;

                &_limit {
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 16px;
                        margin-left: 5px;
                    }
                }

                &_semester {
                    display: flex;
                    font-weight: 600;
                }
            }
        }

        @media screen and (max-width:1100px) {
            grid-template-columns: 1fr 1fr 1fr;
        }


        @media screen and (max-width:500px) {
            grid-template-columns: 1fr;

        }
    }

    .active_course_table_pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
`
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { axiosInstance } from '../../../api/axiosInstance'
import CoverPhoto from "../../../assets/images/b3.png"
import { Rate } from 'antd'
import { FaUser } from 'react-icons/fa'
import CourseDetailPageTab from './CourseDetailPageTab'
import { useParams } from 'react-router-dom'

const CourseVideoLeftContent = ({ courseInfo }) => {
    const [ratingVal, setRatingVal] = useState(1)
    const { id } = useParams()
    /*  const getRatingValue = async () => {
         try {
             const response = await axiosInstance.get(`/Assessments/GetAssessmentResultByCourseId/${id}`)
 
             setRatingVal(response.data.average)
         }
         catch (err) {
 
         }
     } */


    const courseImageList = useMemo(() => {
        return courseInfo?.courseImages
    }, [courseInfo])

    const courseRegister = useMemo(() => {
        let count = courseInfo?.courseRegisters?.length
        if (count == 0) {
            return 1000
        }
        else {
            return count
        }
    }, [courseInfo])

    useEffect(() => {
        /*     getRatingValue() */
    }, [])


    const getCoverPhoto = () => {

        if (courseInfo?.url == "" || courseInfo?.url == null || courseInfo?.url == undefined || courseInfo?.url == "0") {
            return CoverPhoto
        }
        return courseInfo?.url
    }


    return (
        <VideoPageLeftContentWrapper>
            <VideoPageleftContentGeneralInfoWrapper>
                <img src={getCoverPhoto()} alt="" className='videoPageCoverPhoto' />
                <div className='videoPageContentInfoSection' >
                    <h5 className='courseInfo_name' >
                        {courseInfo?.name}
                    </h5>
                    <div className='like_container'>
                        <Rate disabled value={5} />
                       {/*  <div className='like_btn'>
                            <FaUser />
                            {courseRegister}
                        </div> */}
                    </div>
                    <h6>
                        Katılım Sertifikalı
                    </h6>
                </div>
            </VideoPageleftContentGeneralInfoWrapper>
            <CourseDetailPageTab courseInfo={courseInfo} />
            {/* <VidePageTab data={data} status={statusControl.message} /> */}
        </VideoPageLeftContentWrapper>
    )
}

export default CourseVideoLeftContent


export const VideoPageLeftContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;

    @media screen and (max-width:1100px) {
      width: 100%;
    }

    @media screen and (max-width:600px) {
      width: 100%;
    }
`

export const VideoPageleftContentGeneralInfoWrapper = styled.div`
    display: flex;
    background-color: white;
    padding: 20px;
    /* border-radius: 5px; */
    column-gap: 30px;
    border-bottom: 4px solid whitesmoke;


    @media screen and (max-width:600px) {
        flex-direction: column;
        padding: 10px;
    }


    .videoPageCoverPhoto{
        height: 200px;
        width: 350px;
        border-radius: 10px;
        min-width: 350px;


        @media screen and (max-width:600px) {
            width: 100%;
            min-width: 0;
        }
    }

    .videoPageContentInfoSection{
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 20px;
        h5{
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 2px;
            background-color: #F6F6F6;
            width: 100%;
            padding: 10px;
            color: black;
            border-radius: 10px;
        }
        h6{
            font-size: 16px;
        }

        .like_container{
            display: flex;
            align-items: center;
            column-gap: 30px;
            font-size: 16px;

            .like_btn {
                display: flex;
                align-items: center;
                column-gap: 5px;
            }
        }

        .courseInfo_name{
            text-transform: capitalize;

            @media screen and (max-width:600px){
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
`
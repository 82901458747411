import React, { useEffect, useMemo, useState } from 'react'
import { getDynamicUserSearchApi, getUserListApi } from '../../../api/UserApi'
import { toast } from 'react-toastify'
import { MdModeEdit } from 'react-icons/md'
import { BsTrash } from 'react-icons/bs'
import { Card, Col, Row } from 'reactstrap';
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { PropagateLoader } from 'react-spinners'
import { Select, Input as AntdInput, Button, Table as AntTable } from "antd"
import "./StudentTable.scss"
import styled from 'styled-components'
import { FaSms } from 'react-icons/fa'



const StudentTable = () => {
  const [studentList, setStudentList] = useState([])
  const [filterType, setFilterType] = useState("firstName")
  const [totalCount, setTotalCount] = useState(0)
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const staticPageNumber = 10
  const [smsModalInfo, setSmsModalInfo] = useState({
    visible: false
  })

  const getUserDataList = async (page) => {
    try {
      setLoading(true)
      const response = await getUserListApi({
        page: page,
        pageSize: staticPageNumber,
        roleId: 3
      })

      setStudentList(response.data.items)
      setTotalCount(response.data.count)
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserDataList(0)
  }, [])



  const dynamicSearch = async (e, page = 0) => {
    try {
      setLoading(true)
      if (e !== "") {
        const body = {
          "sort": [
            {
              "field": "firstname",
              "dir": "asc"
            }
          ],
          "filter": {
            "field": filterType,
            "operator": "contains",
            "value": e,
          }
        }

        const response = await getDynamicUserSearchApi(body, {
          page: page,
          pageSize: staticPageNumber,
          roleId: 3
        }
        )
        const selected = response.data.items
        setStudentList(selected)
        setTotalCount(response.data.count)
      }
      else {
        getUserDataList(0)
      }
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }



  return (
    <>

      <StyledHeaderContainer >
        <StyledFilterContainer>
          <div style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px"
          }} >
            <StyledSelectInput defaultValue={"firstName"} onChange={(e) => {
              setFilterType(e)
            }}

              options={[
                {
                  "value": "firstName",
                  "label": "İsime göre ara"
                },
                {
                  "value": "lastName",
                  "label": "soyisime göre ara"
                },
                {
                  "value": "email",
                  "label": "emaile göre ara"
                },
                {
                  "value": "phone",
                  "label": "telefona göre ara"
                },
                {
                  "value": "tc",
                  "label": "Tc'ye göre ara"
                }
              ]}
            >
            </StyledSelectInput>
            <StyledAntdInput
              type="text"
              placeholder="Arama yap..."
              onChange={(e) => {
                dynamicSearch(e.target.value)
                setSearchTerm(e.target.value)
              }}
            />
          </div>
          {<Button type='primary' onClick={() => {
            navigate("/panel/sms/ogrenci")
          }} style={{
            display: "flex",
            columnGap: "5px",
            alignItems: "center"
          }} >
            <FaSms /> SMS
          </Button>}
        </StyledFilterContainer>


      </StyledHeaderContainer >
      <AntTable
        loading={loading}
        size='middle'
        dataSource={studentList ?? []}
        scroll={{
          x: 800
        }}
        pagination={{
          current: selectedPage,
          pageSize: staticPageNumber,
          total: totalCount,
          onChange: (e) => {
            if (searchTerm == "") {
              getUserDataList(e - 1)
              setSelectedPage(e)
            }
            else {
              dynamicSearch(searchTerm, e - 1)
              setSelectedPage(e)
            }
          }
        }}
        columns={[{
          title: "İsim",
          render: (row, record) => {
            return (
              <span> {record?.firstName} </span>
            )
          },
          sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
        },
        {
          title: "Soyisim",
          render: (row, record) => {
            return (
              <span> {record?.lastName} </span>
            )
          },
          sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
          title: "Email",
          render: (row, record) => {
            return (
              <span> {record?.email} </span>
            )
          },
          sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
          title: "Telefon",
          render: (row, record) => {
            return (
              <span> {record?.phone} </span>
            )
          }

        },
        {
          title: "Tc No",
          render: (row, record) => {
            return (
              <span>
                {record?.tc}
              </span>
            )
          }
        },
        {
          title: "İşlem",
          render: (row, record) => {
            return (
              <Button type='primary' onClick={() => {
                navigate(`/panel/ogrenci/duzenle/${row.id}`)
              }}  >
                <MdModeEdit />
              </Button>
            )
          }
        }


        ]}

      />



    </>
  )
}

export default StudentTable

const StyledSelectInput = styled(Select)`
  width: 200px;
  height: 35px;
  @media screen  and (max-width:500px) {
      width: 160px;
  }
`

const StyledAntdInput = styled(AntdInput)`
  width: 200px;
  height: 35px;
  border: 1px solid #1677FF;

  @media screen  and (max-width:500px) {
      width: 160px;
  }

`
const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
margin-top: 10px;
  row-gap: 10px;
  @media screen  and (max-width:650px) {
      flex-direction: column;
      align-items: start;
  }

`

const StyledFilterContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width:500px) {
    justify-content: space-between;
  }

`

const StyledAddBtn = styled(Button)`

  
@media screen  and (max-width:650px) {
max-width:420px;
      width: 100%;
  }
`
import React, { useContext, useEffect, useMemo, useState } from 'react'
import TablePageContainer from '../Common/TablePageContainer'
import { Button, Select, Table } from "antd"
import styled from 'styled-components'
import { UserContext } from '../../context/user'
import { axiosInstance } from '../../api/axiosInstance'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import EgitmenSoruEkle from './EgitmenSoruEkle'
import { uniqueId } from 'lodash'
import { FaEye } from 'react-icons/fa'
import EgitmenSoruGuncelle from './EgitmenSoruGuncelle'

const EgitmenSoruHavuzu = () => {
    const [context, dispatch] = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [branchLiset, setBranchList] = useState([])
    const [selectedBranch, setSelectedBranch] = useState()
    const [selectedStatus, setSelectedStatus] = useState(0)
    const [questionList, setQuestionList] = useState([])
    const [editMode, setEditMode] = useState({
        mode: true,
        data: []
    })
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        data: null
    })
    const [addQuestionData, setAddQuestionData] = useState({
        questionContent: "",
        point: 0,
        answers: [
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "a"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "b"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "c"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "d"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "e"
            }
        ],
        branchId: ""
    })
    const teacherId = useMemo(() => {
        return context?.userId
    }, [context])


    const getQuestionList = async (branchId, branchQuestionStatus) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/BranchQuestions/GetAllByBranchId", {
                branchId: branchId,
                branchQuestionStatus,
                pageRequest: {
                    page: 0,
                    pageSize: 1000
                }
            })
            setQuestionList(response.data.items)
        }
        catch (err) {


        }
        finally {
            setLoading(false)

        }
    }



    const getTeacherBranches = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post(`/TeacherBranches/GetAllByUserId?userId=${context?.userId}`, {
                page: 0,
                pageSize: 500
            })
            setBranchList(response.data.items)
            setSelectedBranch(response.data.items[0]?.branch?.id)

            getQuestionList(response.data.items[0]?.branch?.id, 0)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


   /*  const NoticesJwtTest = async () => {
        try {
            await axiosInstance.post("/notices/getall", {
                page: 0,
                pageSize: 10
            })
        }
        catch (err) {

        }
    } */


    useEffect(() => {
        getTeacherBranches()
       
    }, [])

    return (
        <div className='page-content'>
            <TablePageContainer header={"Soru Havuzu"} visible={false}>
                <StyledHeader>
                    <FilterContainer>
                        <StatusSelect
                            value={selectedStatus}
                            onChange={(e) => {
                                setSelectedStatus(e)
                                getQuestionList(selectedBranch, e)
                            }}
                            placeholder="Durum"
                            options={[
                                {
                                    "label": "Bekleyen",
                                    "value": 0
                                },
                                {
                                    "label": "Reddedilen",
                                    "value": 1
                                },
                                {
                                    "label": "Onaylanan",
                                    "value": 2
                                }
                            ]}

                        />
                        <BranchSelect
                            placeholder="Branş Seçiniz"
                            value={selectedBranch}
                            onChange={(e) => {
                                setSelectedBranch(e)
                                getQuestionList(e, selectedStatus)
                            }}
                            options={branchLiset.map(item => {
                                return {
                                    "label": item?.branch.name,
                                    "value": item?.branch?.id
                                }
                            })}

                        />

                    </FilterContainer>
                    <Button type='primary' onClick={() => {
                        setModalInfo({
                            visible: true,
                            data: null
                        })
                    }} >
                        Soru Ekle
                    </Button>
                </StyledHeader>
                <Table
                    loading={loading}
                    scroll={{
                        x: 700
                    }}
                    dataSource={questionList}
                    columns={[
                        {
                            "title": "id",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.id}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "isim",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.question}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "Toplam Kullanım",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.totalUsage}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "Puan",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.point}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "Branş",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.branch?.name}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Sorular",
                            render: (row, record) => {
                            
                                return (
                                    <Button type='primary' onClick={() => {
                                        setEditMode({
                                            visible: true,
                                            data: record
                                        })

                                    }} >
                                        <FaEye />
                                    </Button>
                                )
                            }
                        },
                        {
                            "title": "Durum",
                            render: (row, record) => {
                                return (
                                    <p style={{ padding: "5px 8px", backgroundColor: "orange", borderRadius: "5px", width: "max-content", color: "white" }}  >
                                        {record.status}
                                    </p>
                                )
                            }
                        },
                    ]}
                />

            </TablePageContainer>

            <EgitmenSoruEkle branchList={branchLiset} modalInfo={modalInfo} setModalInfo={setModalInfo} addQuestionData={addQuestionData} setAddQuestionData={setAddQuestionData} />
            <EgitmenSoruGuncelle refreshData={() => {
                getQuestionList(selectedBranch, selectedStatus)
            }} branchList={branchLiset} editMode={editMode} setEditMode={setEditMode} />
        </div>
    )
}

export default EgitmenSoruHavuzu

const StyledHeader = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border: 1px dashed gray;
    border-radius: 10px;
`

const BranchSelect = styled(Select)`
    width:200px;

`
const StatusSelect = styled(Select)`
width:200px;

`

const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
`
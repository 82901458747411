
import MainNavbar from "../../components/navbar/MainNavbar";
import "./index.scss"
import MainFooter from "../../components/mainFooter/MainFooter";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button } from "antd";
import { axiosInstance } from "../../../api/axiosInstance";
import { useEffect, useState } from "react";
import GuncelBg from "../../../assets/images/guncelBg.png"
import TopBgElement from "../TopBgElement/TopBgElement";
import { FaNewspaper } from "react-icons/fa";
import OtherCourseSlider from "../../components/OtherCourseSlider/OtherCourseSlider";
import { toast } from "react-toastify";

const GuncelDetay = () => {

    const [newsData, setNewsData] = useState()
    const { id } = useParams()
    const [allNews, setAllNews] = useState([])
    const navigate = useNavigate()


    const getTopicalData = async () => {
        try {
            const response = await axiosInstance.get(`/news/getById/${id}`)
            setNewsData(response.data)

            if (!response.data) {
                toast.error("İçerik bulunamadı", {
                    position: "top-center",
                    autoClose: 2000
                })
                navigate("/guncel")
            }
        }
        catch (err) {
            navigate("/guncel")
        }
    }

    const getAllTopical = async () => {
        try {
            const response = await axiosInstance.post("/news/getAll", {
                page: 0,
                pageSize: 10
            })
            setAllNews(response.data.items.filter(item => item.id != id))

        }
        catch (err) {

        }
    }

    useEffect(() => {
        window.scroll(0, 0)
    }, [id])


    useEffect(() => {
        getTopicalData()
        getAllTopical()
    }, [id])

    return (
        <StyledMainContainer>
            <Helmet>
                <meta name="description" content={"KUZEM Eğitim platformumuzdaki en son güncellemeleri ve gelişmeleri takip edin."} />
                <meta name="keywords" content={'Güncel, Gelişmeler, Eğitim Platformu, Haberler, Duyurular'} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.kuzem.org/guncel" />
                <meta property="og:image" content={GuncelBg} />
            </Helmet>
            <MainNavbar />
            <TopBgElement img={GuncelBg} mainTitle={"Güncel"} subTitle={`${newsData?.title} `} />

            <StyledContainer>
                <div className="guncel_left_section" >
                    <img className="content_img" src={newsData?.imageUrl} alt="" />



                    <div className="guncel_detail">
                        <div className="label_wrapper" >
                            <h2 className="guncel_label">

                                {newsData?.title}

                            </h2>
                            <span className="label_date"  >
                                {new Date(newsData?.createdAt).toLocaleDateString()}
                            </span>
                        </div>
                        <p>
                            {newsData?.description}
                        </p>
                    </div>
                </div>
                <div className="other_texts_container">
                    <p className="other_texts_label">
                        Güncel
                    </p>
                    <div className="other_guncel_wrapper">
                        {
                            allNews.length !== 0 ? allNews?.map(item => {
                                return (
                                    <>
                                        <p className="other_news_element" onClick={() => {
                                            navigate(`/guncel/${item?.id}`)
                                        }} >
                                            <FaNewspaper /> {item?.title?.length > 40 ? `${item?.title?.substring(0, 40)}...` : item.title}
                                        </p>
                                        {/* <Button type="primary" key={item.id} onClick={() => {
                                        navigate(`/guncel/${item?.id}`)
                                    }}  >

                                        {item?.title?.length > 40 ? `${item?.title?.substring(0, 40)}...` : item.title}

                                    </Button> */}
                                    </>



                                )
                            }) : (
                                <Alert type="info" message="Başka yazı bulunamadı" />
                            )
                        }
                    </div>

                </div>
            </StyledContainer>
            <KomekSliderContainer>
                <OtherCourseSlider />
            </KomekSliderContainer>


            <MainFooter visibility={'hidden'} ></MainFooter>
        </StyledMainContainer>
    )
}

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding: 20px 120px;
    margin-bottom: 100px;
    column-gap: 30px;
    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .guncel_left_section{
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .label_wrapper{
            background-color: white;
            border-radius: 5px;
            border-bottom-width:1px;
            border-bottom-style: dashed;
            border-bottom-color: #eee;
            padding-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width:500px) {
                font-size: 16px;
                padding-left: 10px;
            }
            .guncel_label{
                font-size: 18px;
                font-weight: bold;

            }

                .label_date{
                    color: gray;
                    opacity: .3;
                    
                }
        }

       
        img{
            width: 100%;
            height: 350px;
            border-radius: 10px;
            object-fit: contain;


            @media screen  and (max-width:600px) {
                object-fit: contain;
                height: 150px;
            }

        }

        .guncel_detail{
            background-color: white;
            border-radius:10px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            padding-bottom: 40px;
            h5 {
                font-weight: 600;
                font-size: 20px;
            }
            p {
                letter-spacing: 1px;
            }
        }
    }

    .other_texts_container{
        display: flex;
        background-color: white;
        border-radius: 10px;
        flex-direction: column;
        padding-top: 30px;
        padding: 10px;
        height: max-content;

        .other_texts_label{
            text-align: start;
            border-bottom: 1px solid gray;
            font-size: 16px;
            padding-bottom: 5px;
            margin-bottom: 30px;
        }

        .other_guncel_wrapper{
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            margin-bottom: 70px;
            .other_guncel_element{
                border: 2px solid #BBB1ED;
                border-radius: 5px;
                padding: 10px;
                font-size: 12px;
                cursor: pointer;
             }



             .other_news_element{
                display: flex;
                /* align-items: center; */
                column-gap: 10px;
                font-size: 14px;
                border-bottom-width: 1px;
                border-bottom-style: dashed;
                border-bottom-color: #eeeeee;
                padding-bottom: 5px;
                cursor: pointer;
             }
        }

  
      
    }
`

const KomekSliderContainer = styled.div`
  padding: 20px 120px;
    margin-bottom: 100px;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }


    .slider_item_img{
        height: 80px;
        object-fit: contain;
    }
`


const StyledMainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`


export default GuncelDetay

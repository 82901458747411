import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import SmsDashboard from '../../Components/SmsDashboard/SmsDashboard'

const SmsPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer header={"Sms Sayfası"} visible={false} >
                <SmsDashboard />
            </TablePageContainer>
        </div>
    )
}

export default SmsPage
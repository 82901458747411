import React, { useEffect, useMemo, useState } from 'react'
import { CourseRegisterGetAll, CourseRegisterStudentApi, GetCalendarForAllRoles, getDetailCourseApi } from '../../../api/Course'
import { Collapse, Select as AntSelect, Alert, Button, Modal, Skeleton } from 'antd'
import { Col, Label, Row } from 'reactstrap'
import { SlArrowDown } from 'react-icons/sl'
import test from "../../../assets/images/b1.png"
import Accordion from "react-bootstrap/Accordion"
import CustomAccordionHeader from '../Common/CustomAccordionHeader'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../../api/axiosInstance'
import { toast } from 'react-toastify'


const StudentCourseTable = () => {
    const [loading, setLoading] = useState(false)
    const [courseType, setCourseType] = useState(0)
    const [courseData, setCourseData] = useState([])
    const navigate = useNavigate()

    const convertTrFormat = (date) => {
        const tarih = new Date(date);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };

        const turkishDate = new Intl.DateTimeFormat('tr-TR', options).format(tarih);

        return turkishDate;
    }



    const getStudentCourse = async () => {

        try {
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 10
            })
            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: response.data.count
            })


            setCourseData(responsev2.data.items)

        }
        catch (err) {

        }
        finally {

        }

    }

    const mainData = useMemo(() => {
        if (courseType === 0) {
            return courseData;
        } else if (courseType === 1) {
            return courseData?.filter(el => el?.type === "Offline");
        } else {
            return courseData?.filter(el => el?.type === "Online");
        }
    }, [courseData, courseType]);


    useEffect(() => {
        getStudentCourse()
    }, [])


    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "30px" }} >
                <Skeleton />
            </div>
        )
    }

    return (
        <>
            <StyledHeader>
                <StyledAntSelect
                    value={courseType}
                    onChange={(e) => {
                        setCourseType(e)
                    }}
                    options={[
                        {
                            label: "Hepsi",
                            value: 0,
                        },
                        {
                            "label": "Video Eğitim",
                            "value": 1
                        },
                        {
                            "label": "Canlı Eğitim",
                            "value": 2
                        }
                    ]}
                >
                </StyledAntSelect>
            </StyledHeader>
            {
                mainData.length !== 0 ? mainData?.map((el, index) => {

                    return (
                        <Collapse key={`${index}`} defaultActiveKey={[0, 1, 2]} style={{ marginTop: "30px" }} className='course_collapse_panel' expandIcon={({ isActive }) => {
                            return null
                        }} >
                            <Collapse.Panel header={CustomAccordionHeader(el, navigate)} key={index}>
                                {
                                    el.type == "Online" ? (
                                        <Row>

                                            <Col lg={6} >
                                                <div className='course_card_item ' >
                                                    <Label className='course_card_item_title'>
                                                        Kurs Başlangıç Tarihi
                                                    </Label>
                                                    <p>
                                                        {convertTrFormat(el.startDate)}
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col lg={6} >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Kurs Bitiş Tarihi
                                                    </Label>
                                                    <p>
                                                        {convertTrFormat(el.endDate)}
                                                    </p>
                                                </div>

                                            </Col>
                                            <Col lg={4} >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Eğitmen
                                                    </Label>
                                                    <p>
                                                        {el?.courseAdministrator}
                                                    </p>
                                                </div>

                                            </Col>
                                            <Col lg={4} >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Dönem
                                                    </Label>
                                                    <p>
                                                        {el.semester}
                                                    </p>
                                                </div>
                                            </Col>


                                            <Col lg={4}  >
                                                <div className='course_card_item '  >
                                                    <Label className='course_card_item_title' >
                                                        Kurs Türü
                                                    </Label>
                                                    <p>
                                                        Canlı Eğitim
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={12} >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Açıklama
                                                    </Label>
                                                    <p>
                                                        {el.description}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={12} >
                                                <Button type='primary' danger style={{
                                                    width: "100%",
                                                    marginTop: "15px"
                                                }}
                                                    onClick={() => {
                                                        Modal.confirm({
                                                            title: `${el?.name} isimli kurstan kaydınızı silmek istediğinize emin misiniz?`,
                                                            type: "confirm",
                                                            okText: "Eminim",

                                                            cancelText: "İptal",
                                                            onOk: async () => {
                                                                try {
                                                                    setLoading(true)
                                                                    const response = await axiosInstance.delete(`/courseRegisters/delete/${el?.courseRegisterId}`)

                                                                    await getStudentCourse()
                                                                    setCourseType(0)

                                                                    toast.success(`${el?.name} isimli kurstan kaydınız silindi`, {
                                                                        autoClose: 4000,
                                                                        position: "top-center"
                                                                    })
                                                                }
                                                                catch (err) {

                                                                }
                                                                finally {
                                                                    setLoading(false)
                                                                }
                                                            }
                                                        })
                                                    }}

                                                >
                                                    Kurstan kaydımı silmek istiyorum
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row>
                                            <Col lg={6}  >
                                                <div className='course_card_item '  >
                                                    <Label className='course_card_item_title' >
                                                        Kurs Türü
                                                    </Label>
                                                    <p>
                                                        Video Eğitim
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={6}  >
                                                <div className='course_card_item '  >
                                                    <Label className='course_card_item_title' >
                                                        Eğitmen
                                                    </Label>
                                                    <p>
                                                        {el.courseAdministrator}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={12} >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Açıklama
                                                    </Label>
                                                    <p>
                                                        {el.description}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={12} >
                                                <Button type='primary' danger style={{
                                                    width: "100%",
                                                    marginTop: "15px"
                                                }}
                                                    onClick={() => {
                                                        Modal.confirm({
                                                            title: `${el?.name} isimli kurstan kaydınızı silmek istediğinize emin misiniz?`,
                                                            type: "confirm",
                                                            okText: "Eminim",

                                                            cancelText: "İptal",
                                                            onOk: async () => {
                                                                try {
                                                                    setLoading(true)
                                                                    const response = await axiosInstance.delete(`/courseRegisters/delete/${el?.courseRegisterId}`)

                                                                    await getStudentCourse()
                                                                    setCourseType(0)

                                                                    toast.success(`${el?.name} isimli kurstan kaydınız silindi`, {
                                                                        autoClose: 4000,
                                                                        position: "top-center"
                                                                    })
                                                                }
                                                                catch (err) {

                                                                }
                                                                finally {
                                                                    setLoading(false)
                                                                }
                                                            }
                                                        })
                                                    }}

                                                >
                                                    Kurstan kaydımı silmek istiyorum
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }

                            </Collapse.Panel>
                        </Collapse>
                    )
                }) : (
                    <div style={{ marginTop: "30px" }}>
                        <Alert message="Herhangi bir kayıt bulunamadı" type="warning" />
                    </div>

                )
            }
        </>
    )
}

export default StudentCourseTable

const StyledHeader = styled.div`
    border-bottom: 1px dashed black;
    padding-bottom: 10px;

    display: flex;
    /* justify-content: flex-end; */
`

const StyledAntSelect = styled(AntSelect)`
    width: 100%;
    height: 35px;
    @media screen and (max-width:600px) {
        width: 100%;
    }
`

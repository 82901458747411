import React, { useContext, useEffect, useMemo, useState } from 'react'
import { StyledJoinRightSectionWrapper, VideoPageBreadCrumbContainer, VideoPageContainer, VideoPageHeader, VideoPageleftContentGeneralInfoWrapper, VideoPageLeftContentWrapper, VideoPageMainContentContainer, VideoPageRightContentWrapper } from './videoPageStyle'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LogoBelediye from "../../../../src/assets/images/logo.png"
import { courseContentForCourseId, CourseViewsForStudentApi } from '../../../api/CourseContent'
import VerticalLine from "../../../assets/images/lineVertical.png"
import ProfileDropdown from '../../../Components/Common/ProfileDropdown'
import { Button as AntButton, Breadcrumb, Rate, Skeleton, Tabs } from "antd"
import { UserContext } from '../../../context/user'
import CoverPhoto from "../../../assets/images/b3.png"
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai'
import { FaUser } from 'react-icons/fa'
import VidePageTab from './VidePageTab'
import styled from 'styled-components'
import { IoMdPlayCircle } from 'react-icons/io'
import SameCourse from './SameCourse'
import { TiTickOutline } from "react-icons/ti";
import { LuPercent } from "react-icons/lu";
import { Modal as AntModal } from "antd"
import { registerCourseApi } from '../../../api/Course'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../api/axiosInstance'
import VideoPageFooter from './VideoPageFooter'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { decode } from 'html-entities'

const VideoPage3 = () => {
    const { id } = useParams()
    const [statusControl, setStatusControl] = useState({
        status: false,
        message: ""
    })
    const [openCv, setOpenCv] = useState({
        visible: false,
        html: "",
        data: ""
    })
    const navigate = useNavigate()
    const [context, dispatch] = useContext(UserContext)

    const [responseViewsList, setResponseViewsList] = useState({
        progress: "",
        items: []
    })
    const [ratingVal, setRatingVal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])


    const getRatingValue = async () => {
        try {
            const response = await axiosInstance.get(`/Assessments/GetAssessmentResultByCourseId/${id}`)
            
            setRatingVal(response.data.average)
        }
        catch (err) {

        }
    }


    const getCourseVideo = async () => {
        try {
            setLoading(true)
            const response = await courseContentForCourseId({
                page: 0,
                pageSize: 100
            }, id)
    
            let temp = response.data.items
 
            setStatusControl({
                message: response.data.message,
                status: response.data.status
            })
            setData(temp)

            if (response.data.status) {
                const responseViewsData = await CourseViewsForStudentApi(id)
                setResponseViewsList(responseViewsData.data)
            }


        }
        catch (err) {
          
        }
        finally {
            setLoading(false)
        }
    }
    

    const circlePercent = useMemo(() => {
        if (statusControl.status) {
      
            let percentValue = responseViewsList.progress.split("%")[1]
    
            return parseInt(percentValue?.trim())
        }

    }, [responseViewsList, statusControl])

    const courseInfo = useMemo(() => {
     

        let lastElement = data[data.length - 1]

        return lastElement?.course
    }, [data])

    const courseCategory = useMemo(() => {
        return courseInfo?.category?.name
    }, [courseInfo])


    const courseType = useMemo(() => {
        return courseInfo?.type
    }, [courseInfo])

    const statusType = useMemo(() => {
     
        return statusControl?.status
    }, [statusControl])



    const statusRateControl = () => {
        if (statusControl.message == "Erişebilmeniz için kayıt olmanız gerekmektedir.") {
            return false
        }
        else if (statusControl.message == "Lütfen giriş yapınız.") {
            return false
        }
        else if (context.role == "Süper Admin" || context.role == "Öğretmen") {
            return false
        }
        return true
    }


    const items = [
        {
            key: '1',
            label: 'Eğitmen',
            children: (
                <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    <AntButton type='primary' onClick={() => {
                        if (courseInfo?.courseAdministrator) {
                            getTeacherCv()
                        }

                    }} >
                        {courseInfo?.courseAdministrator ? courseInfo.courseAdministrator : "Eğitmen henüz seçilmedi"}
                    </AntButton>
                </div>
            ),
        },
    ];



    if (statusRateControl()) {
        items.push(
            {
                key: "3",
                label: "Puanla",
                children: (
                    <Rate onChange={async (e) => {
                        try {
                            const response = await axiosInstance.post("/Assessments/Add", {
                                point: e,
                                courseId: id
                            })
                            toast.success("Puanınız başarıyla verildi", {
                                autoClose: 2000,
                                position: "top-center"
                            })
                        }
                        catch (err) {

                        }
                    }} />
                )
            }
        )
    }



    const StatusBtnControl = useMemo(() => {
        if (statusControl?.message == "Erişebilmeniz için kayıt olmanız gerekmektedir.") {
            return (
                <VideoPageJoinStyledBtn type='primary' onClick={() => {
                    AntModal.confirm({
                        title: "Kayıt olmak istediğinize emin misiniz ?",
                        cancelText: "İptal",
                        okText: "Eminim",

                        onOk: async () => {
                            try {
                                const response = await registerCourseApi({
                                    courseId: id,
                                    userId: context.userId
                                })
                                toast.success("Kursa kaydınız yapıldı", {
                                    autoClose: 2000,
                                    position: "top-center"
                                })
                                await getCourseVideo()
                            }
                            catch (err) {

                            }
                        }
                    })
                }}  >
                    <IoMdPlayCircle id='play_btn' />
                    <span>
                        Programa Katıl
                    </span>
                </VideoPageJoinStyledBtn>
            )
        }
        else if (statusControl?.message == "Lütfen giriş yapınız.") {
            return (
                <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
                    <p style={{ textTransform: "capitalize", fontSize: "13px" }}>
                        Eğitime başlamak için lütfen giriş yapınız.
                    </p>
                    <VideoPageJoinStyledBtn type='primary' onClick={() => {
                        navigate("/giris")
                    }} >
                        <span>
                            Giriş Yapınız
                        </span>
                    </VideoPageJoinStyledBtn>
                </div>

            )
        }
        return (
            <StyledJoinRightSectionWrapper>
                <div className='right_section_info_element'>
                    <div className='icon_wrapper' >
                        <TiTickOutline id='right_icon' />
                        <span>
                            Katılım durumu
                        </span>
                    </div>
                    <span className='right_result'>
                        Devam Ediyor
                    </span>
                </div>
                <div className='right_section_info_element'>
                    <div className='icon_wrapper' >
                        <LuPercent id='right_icon' />
                        <span>
                            Tamamlanma
                        </span>
                    </div>
                    <span className='right_result'>
                        %{circlePercent}
                    </span>
                </div>
                <VideoPageJoinStyledBtn type='primary' onClick={() => {
                    navigate(`/kurs/player/${id}`)
                }} >
                    <IoMdPlayCircle id='play_btn' />
                    <span>
                        Devam et
                    </span>
                </VideoPageJoinStyledBtn>
            </StyledJoinRightSectionWrapper>
        )
    }, [statusControl, circlePercent])


    const getCourseByıd = async () => {
        try {
            const response = await axiosInstance.get(`/courses/getById/${id}`)
          
        }
        catch (err) {
          

        }
    }


    useEffect(() => {
        const requests = async () => {
            await getCourseByıd()
            await getCourseVideo()
            await getRatingValue()
        }
        requests()
    }, [id])


    if (loading) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, height: "100vh", padding: "0 60px" }}>
                <Skeleton active={true} />
            </div>

        )
    }




    return (
        <VideoPageContainer>
            <VideoPageHeader>
                <div className='videoPageHeader_left_section' >
                    <Link to={"/"}>
                        <img src={LogoBelediye} alt="" id='logoBel1' />
                    </Link>
                    <div id='lineVertical'>

                    </div>
                    {/* <img src={VerticalLine} alt="" id='lineVertical' /> */}
                    <h5 className='offline_video_page_course_title' >
                        {courseInfo?.name?.length > 20 ? `${courseInfo?.name?.substring(0, 20)}...` : courseInfo?.name}
                    </h5>
                </div>
                {
                    statusControl?.message == "Lütfen giriş yapınız." ? (

                        <AntButton type='primary' onClick={() => {
                            navigate("/giris")
                        }} >
                            Oturum Aç
                        </AntButton>
                    ) : (
                        <ProfileDropdown visiblePanel={true} logoutMethod={() => {
                            window.location.reload()
                        }} />
                    )
                }

            </VideoPageHeader>
            <VideoPageBreadCrumbContainer>
                <Breadcrumb
                    separator=">"
                    items={[
                        {
                            title: "Offline",
                        },
                        {
                            title: (
                                <p style={{ textTransform: "capitalize" }}>
                                    {courseCategory}
                                </p>
                            ),
                        },
                        {
                            title: courseInfo?.name,
                        },
                    ]}
                />
            </VideoPageBreadCrumbContainer>
            <VideoPageMainContentContainer>
                <VideoPageLeftContentWrapper>
                    <VideoPageleftContentGeneralInfoWrapper>
                        <img src={CoverPhoto} alt="" className='videoPageCoverPhoto' />
                        <div className='videoPageContentInfoSection' >
                            <h5 style={{ textTransform: "capitalize" }}>
                                {courseInfo?.name}
                            </h5>
                            <div className='like_container'>
                                <Rate disabled value={ratingVal} />
                                <div className='like_btn'>
                                    <FaUser />
                                    3000
                                </div>
                            </div>
                            <h6>
                                Katılım Sertifikalı
                            </h6>
                        </div>
                    </VideoPageleftContentGeneralInfoWrapper>
                    <VidePageTab data={data} status={statusControl.message} />
                </VideoPageLeftContentWrapper>
                <VideoPageRightContentWrapper>
                    {
                        context.role == "Süper Admin" || context.role == "Öğretmen" || courseType == "Online" ? null : (
                            <div className='rightSectionTopElement'>
                                {
                                    StatusBtnControl
                                }
                            </div>
                        )
                    }
                    <div className='rightSectionTeacherWrapper'>
                        <Tabs items={items} />
                    </div>

                </VideoPageRightContentWrapper>
            </VideoPageMainContentContainer>
            {/* <SameCourse course={courseInfo} /> */}
            <VideoPageFooter />


        </VideoPageContainer >

    )
}

export default VideoPage3

const VideoPageJoinStyledBtn = styled(AntButton)`
    width: 100%;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    #play_btn{
        font-size: 24px;
    }
`

const StyledModalHeader = styled(ModalHeader)`
width: 100%;
    .modal-title{
        width: 100%;
        
    }
`

const StyledEditorContent = styled.div`
    max-width: 100%;

    .ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
        
        img{
            max-width: 100%;
            height: auto;
        }
    
        blockquote {
    border-left: 5px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    color: #666;
    font-style: italic;
  }
`
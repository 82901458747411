import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import "./examQuestionList.scss"
import { ExamUpdateContext } from '../ExamUpdateDashboard'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Pagination, Modal as AntDModal, Button as AntButton, Alert } from 'antd'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { ExamQuestionsUpdateApi, deleteQuestionApi, getExamDetailByIdApi, updateExamAnswerApi } from '../../../api/exam'
import { useParams } from 'react-router-dom'
import { UserContext } from '../../../context/user'
import { CiWarning } from 'react-icons/ci'
import UpdateSectionCreateQuestionModal from './UpdateSectionCreateQuestionModal'


const ExamQuestionList = () => {
    const { id } = useParams()
    const { examDetailData, setExamDetailData, getExamInfo, questionIndex, setQuestionIndex } = useContext(ExamUpdateContext)
    const [tempData, setTempData] = useState()
    const [questionPhoto, setQuestionPhoto] = useState()
    const photoRef = useRef(null)
    const [addQuestionModal, setAddQuestionModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteConfirmModal, setDeleteConfirmModal] = useState({
        visible: false
    })
    const sortByOption = (a, b) => {
        const optionsOrder = ["A", "B", "C", "D", "E"];
        return optionsOrder.indexOf(a.option) - optionsOrder.indexOf(b.option);
    };
    const [modalData, setModalData] = useState({
        visible: false,
        data: null
    })
   

    const examQuestionLength = useMemo(() => {
        if (examDetailData) {
            return examDetailData.examQuestions.length
        }
    }, [examDetailData])

    const deleteQuestionBtnControl = useMemo(() => {
        if (examDetailData) {
            return examDetailData.examQuestions < 3
        }

    }, [examDetailData])

    const updateQuestionProcess = async () => {
   
        let questionEmptyControl = tempData.examAnswers.some(el => el.answer == "")
        let correctAnswerControl = tempData.examAnswers.some(el => el.status)
        if (tempData.question == "") {
            toast.error("Soru boş bırakılamaz", {
                autoClose: 1500
            })
            return;
        }
        else if (questionEmptyControl) {
            toast.error("Cevap şıkkı boş olamaz", {
                autoClose: 1500
            })
            return;

        }
        else if (tempData.point <= 0 || tempData.point > 100) {
            toast.error("Puan 0 ile 100 arasında olmalı", {
                autoClose: 1500
            })
            return;
        }
        else if (examDetailData.type !== "Yazılı" && !correctAnswerControl) {
            toast.error("En az bir tane doğru cevap seçiniz", {
                autoClose: 1500
            })
            return;
        }

        AntDModal.confirm({
            title: "Güncellemek istediğinize emin misiniz?",
            okText: "Eminim",
            cancelText: "İptal",
            onOk: async () => {
                try {
                    setLoading(true)
                    const response = await ExamQuestionsUpdateApi(tempData.id, null, tempData.examId, tempData.point, tempData.question)
                    await updateExamAnswerApi({
                        examAnswers: tempData.examAnswers
                    })


                    await getExamInfo()
                    toast.success(`${questionIndex + 1}. soru güncellendi`, {
                        autoClose: 1500,
                        position: "top-center"
                    })
                }
                catch (err) {

                }
                finally {
                    setLoading(false)
                }
            }
        })
        /*   const response = await ExamQuestionsUpdateApi(tempData.id, null, tempData.examId, tempData.point, tempData.question) */
        /* for (let item of tempData.examAnswers.filter(el => el.status == false)) {
            await updateExamAnswerApi({
                id: item.id,
                answer: item.answer,
                option: item.option,
                status: item.status,
                examQuestionId: tempData.id
            })
        }
        let correctAnswer = tempData.examAnswers.find(el => el.status)
        await updateExamAnswerApi({
            id: correctAnswer.id,
            answer: correctAnswer.answer,
            option: correctAnswer.option,
            status: correctAnswer.status,
            examQuestionId: tempData.id
        }) */
        /*         await updateExamAnswerApi({
                    examAnswers: tempData.examAnswers
                })
        
        
                await getExamInfo()
                toast.success(`${questionIndex + 1}. soru güncellendi`, {
                    autoClose: 1500,
                    position: "top-center"
                }) */





    }



    const changeCorrect = (checked, element) => {
        const veri = tempData.examAnswers.map(el => {
            if (el.id == element.id) {
                return {
                    ...el,
                    status: checked
                }
            }
            else {
                return {
                    ...el,
                    status: false
                }
            }

        })
        setTempData({
            ...tempData,
            examAnswers: veri
        })
    }

    const changeOption = (text, element) => {
        const veri = tempData.examAnswers.map(el => {
            if (el.id == element.id) {
                return {
                    ...el,
                    answer: text
                }
            }
            return el
        })
        setTempData({
            ...tempData,
            examAnswers: veri
        })
    }


    const questPhotoMemo = useMemo(() => {
        if (questionPhoto) {
            return (
                <div>
                    <img src={URL.createObjectURL(questionPhoto)} alt="Question" style={{ width: "100%", maxHeight: "300px", objectFit: "contain" }} />
                </div>
            )
        }
        if (tempData?.imageUrl === "Dosya Seçilmedi.") {
            return (
                <h6 style={{ textAlign: "center" }} >
                    Bu soru için fotoğraf eklenmedi
                </h6>
            )
        } else {
            return (
                <div>
                    <img src={tempData?.imageUrl} alt="Question" style={{ width: "100%", maxHeight: "300px", objectFit: "contain" }} />
                </div>
            )
        }
    }, [tempData, questionPhoto])


    const updateQuestioPhotoRequest = async () => {
        try {
            const examQuestv2 = examDetailData?.examQuestions.find(el => el.id == tempData.id)
            if (questionPhoto) {
                const formData = new FormData()
                formData.append("formFile", questionPhoto)
                const response = await ExamQuestionsUpdateApi(examQuestv2.id, formData, examQuestv2.examId, examQuestv2.point, examQuestv2.question)

                await getExamInfo()
                setModalData({
                    visible: false,
                    data: null
                })
                setQuestionPhoto(null)
                toast.success("Fotoğraf güncellendi", {
                    autoClose: 2000
                })
            }
            else {
                const responsev2 = await ExamQuestionsUpdateApi(examQuestv2.id, null, examQuestv2.examId, examQuestv2.point, examQuestv2.question)
                await getExamInfo()
                setModalData({
                    visible: false,
                    data: null
                })
                setQuestionPhoto(null)
                toast.success("Fotoğraf güncellendi", {
                    autoClose: 2000
                })
            }
        }
        catch (err) {


        }
    }

    const examTypeControl = useMemo(() => {
        if (examDetailData) {
            return examDetailData?.type == "Test"
        }

    }, [examDetailData?.type])

    useEffect(() => {
       
        if (examDetailData) {
            if (examDetailData?.examQuestions?.length !== 0) {
                setTempData(examDetailData.examQuestions[questionIndex])
            }
            else {
                setTempData(undefined)
            }

        }
    }, [examDetailData, questionIndex])



    return (
        <>
            <div className='exam_update_question_view_container' >
                <div className='exam_question_list_wrapper'>
                    <div className='exam_update_question_content_wrapper'>
                        <div className='exam_update_question_label_wrapper'>

                            <p style={{ fontWeight: "bold", marginRight: "20px" }}>
                                Soru içeriği
                            </p>

                            <div>
                                {
                                    tempData && (
                                        <Button size='sm' onClick={() => {
                                            setModalData({
                                                visible: true,
                                                data: tempData
                                            })
                                        }} style={{ marginRight: "10px" }}>
                                            Fotoğrafı gör
                                        </Button>
                                    )
                                }
                                <Button color='primary' size='sm' onClick={() => {
                                    setAddQuestionModal(true)
                                }} className='addQuestion' >
                                    Yeni soru ekle
                                </Button>
                            </div>

                        </div>
                        {
                            tempData && (
                                <Input value={tempData?.question} type='textarea' rows={4} style={{ resize: "none" }} onChange={(e) => {
                                    setTempData({
                                        ...tempData,
                                        question: e.target.value
                                    })
                                    
                                }} />
                            )
                        }

                    </div>
                    <div className='exam_update_question_list_container' >
                        {
                            tempData && (
                                <QuestionPointContianer>
                                    {
                                        examTypeControl && (
                                            <Label className='labels-all'>
                                                Cevap Şıkları
                                            </Label>
                                        )
                                    }
                                    <div style={{ alignItems: "center", display: "flex" }}>
                                        <Label className='labels-all' >
                                            Puan
                                        </Label>
                                        <Input type='number' value={tempData?.point} style={{ width: "100px" }} onChange={(e) => {
                                            setTempData({
                                                ...tempData,
                                                point: e.target.value
                                            })
                                        }} />
                                    </div>
                                </QuestionPointContianer>
                            )
                        }

                        {
                            tempData ? (
                                tempData?.examAnswers?.sort(sortByOption).map((el, index) => {
                                    return (
                                        <div key={index} className='update_question_option_item'  >
                                            <div className='question_option_item_letter' >
                                                {el.option}
                                            </div>
                                            <Input
                                                type='textarea'
                                                rows={1}
                                                onChange={(e) => {
                                                    changeOption(e.target.value, el)
                                                }}
                                                key={index} value={el.answer} style={{ marginLeft: 0, border: "1px solid gray", resize: "none" }} />
                                            <Input checked={el.status} type='checkbox' className='update_question_choice' onChange={(e) => {
                                                changeCorrect(e.target.checked, el)
                                            }} />
                                        </div>
                                    )
                                })

                            ) : (
                                <Alert message="Henüz soru eklenmemiş" type="warning" />
                            )
                        }

                    </div>
                    {
                        tempData && (
                            <UpdateQuestionPaginationContainer type={examTypeControl}  >
                                <Pagination current={questionIndex + 1} total={examQuestionLength} pageSize={1} showLessItems={true} onChange={(e) => {
                                    setQuestionIndex(e - 1)
                                    setTempData(examDetailData.examQuestions[e - 1])
                                }} className='pagination-exam-question' />
                                <UpdateQuestionFooterBtnContainer>

                                    <StyledAntButton loading={loading} type='primary' danger disabled={examQuestionLength < 2} onClick={() => {
                                        AntDModal.confirm({

                                            title: 'Onay',
                                            onOk: async () => {
                                                try {
                                                    setLoading(true)
                                                 
                                                    await deleteQuestionApi(tempData.id)
                                                    await getExamInfo()
                                                    setQuestionIndex(0)
                                                    toast.success("Soru siindi", {
                                                        autoClose: 2000
                                                    })
                                                }
                                                catch (err) {

                                                }
                                                finally {
                                                    setLoading(false)
                                                }
                                            },
                                            content: `${questionIndex + 1}. soruyu silmek istediğinize emin misiniz?`,
                                            okText: "Eminim",
                                            cancelText: "İptal",
                                            okButtonProps: {
                                                style: {
                                                    backgroundColor: "red",
                                                    color: "white"
                                                }
                                            }
                                        });
                                    }}>
                                        {questionIndex + 1}. soruyu sil
                                    </StyledAntButton>

                                    <StyledAntButton loading={loading} type='primary' onClick={() => {
                                        updateQuestionProcess()
                                    }}>
                                        {questionIndex + 1}. soruyu güncelle
                                    </StyledAntButton>
                                </UpdateQuestionFooterBtnContainer>
                            </UpdateQuestionPaginationContainer>
                        )
                    }

                </div>


            </div>
            <Modal isOpen={modalData.visible}   >
                <StyledModalHeader>
                    Fotoğraf
                    <Button size='sm' onClick={() => {
                        photoRef.current.click()
                    }}>
                        Fotoğraf Ekle
                    </Button>
                </StyledModalHeader>
                <ModalBody>
                    {
                        questPhotoMemo
                    }
                </ModalBody>
                <ModalFooter>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <AntButton type='primary' onClick={() => {
                            updateQuestioPhotoRequest()
                        }} >
                            Kaydet
                        </AntButton>
                        <AntButton danger type='primary' onClick={() => {
                      
                            setModalData({
                                visible: false
                            })
                            setQuestionPhoto()
                        }} >
                            Kapat
                        </AntButton>
                    </div>

                </ModalFooter>
            </Modal>
            <input type='file' style={{ display: "none" }} ref={photoRef} onChange={(event) => {
                setQuestionPhoto(event.target.files[0])
            }} />
            <Modal isOpen={deleteConfirmModal.visible} size='md'  >
                <ModalHeader>
                    Onay
                </ModalHeader>
                <ModalBody>
                    <p style={{ fontWeight: "bold" }} >
                        {questionIndex + 1}. soruyu silmek istediğinize emin misiniz ?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <StyledDeleteQuestionFooterContainer>
                        <Button color='danger' >
                            Soruyu sil
                        </Button>
                        <Button>
                            İptal
                        </Button>
                    </StyledDeleteQuestionFooterContainer>
                </ModalFooter>
            </Modal>
            <UpdateSectionCreateQuestionModal refreshRequest={getExamInfo} examType={examDetailData?.type} modalShow={addQuestionModal} setModalShow={setAddQuestionModal} />
        </>

    )
}

export default ExamQuestionList


const StyledModalHeader = styled(ModalHeader)`
   h5{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
   }
`

const QuestionPointContianer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const UpdateQuestionPaginationContainer = styled.div`
            margin-top: ${props => props.type ? "30px" : "40px"};
            display: flex;
            justify-content:space-between;
            align-items: center;
            @media (max-width: 605px) {
                flex-direction: column-reverse;
                row-gap: 20px;
             }


`

const UpdateQuestionFooterBtnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    @media (max-width: 700px) {
    margin-top:10px;
    width: 100%;
    justify-content: space-between;
    }

`

const StyledDeleteQuestionFooterContainer = styled.div`
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
`

const StyledAntButton = styled(AntButton)`


@media screen and (max-width:600px) {
    font-size:10px;
}

`
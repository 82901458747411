import React, { useEffect, useMemo, useState } from 'react'
import TablePageContainer from '../Common/TablePageContainer'
import { Button, Select, Switch, Table, Modal as AntModal, Input, Alert } from "antd"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { axiosInstance } from '../../api/axiosInstance'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { FaEye } from 'react-icons/fa'
import { getAllBranch } from '../../api/Branch'
import EgitmenSoruGuncelle from '../EgitmenSoruHavuzu/EgitmenSoruGuncelle'


const SoruHavuzu = () => {
    const [branchList, setBranchList] = useState([])
    const [activeStatusType, setActiveStatusType] = useState(0)
    const [questionList, setQuestionList] = useState([])
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState({
        visible: false
    })
    const [answerModal, setAnswerModal] = useState({
        visible: false,
        label: "",
        data: []
    })

    const getQuestions = async (statusType = 0) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/BranchQuestions/GetAll", {
                branchQuestionStatus: statusType,
                pageRequest: {
                    page: 0,
                    pageSize: 100
                }
            })
            setQuestionList(response.data.items.map(el => {
                const { branch, ...rest } = el

                return {
                    ...rest,
                    branch: branch.name
                }
            }))
        }
        catch (err) {


        }
        finally {
            setLoading(false)
        }
    }

    const getAllBranchList = async () => {
        try {
            const response = await getAllBranch({
                page: 0,
                pageSize: 1000
            })
            setBranchList(response.data.items)
       
        }
        catch (err) {

        }
    }

    const updateStatus = async (id, status) => {
        try {
            setLoading(true)
            const response = await axiosInstance.put("/BranchQuestions/UpdateStatus", {
                id: id,
                branchQuestionStatus: status
            })
            toast.success("Güncellendi", {
                autoClose: 2000,
                position: "top-center"
            })
            getQuestions(activeStatusType)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    /* const statusControl = (status) => {
        if(status=="")
    } */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value, record) => {
        
            return record[dataIndex]
                ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase())
                : ''
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    const mainData = useMemo(() => {
        return questionList
    }, [questionList, searchText, searchedColumn])

    const getSurveyList = async () => {
        try {
            const response = await surveysGetAll({
                page: 0,
                pageSize: 500
            })
           
            setDataList(response.data.items.reverse())


            setSelectedQuestionContent()

            return response.data.items
        }
        catch (err) {

        }
    }
    useEffect(() => {
        getQuestions()
        getAllBranchList()
    }, [])

    return (
        <div className='' >
            <StyledHeader>
                <StyledSelect
                    value={activeStatusType}
                    onChange={(e) => {
                        setActiveStatusType(e)
                        getQuestions(e)
                    }}
                    placeholder="Durum"
                    options={[
                        {
                            "label": "Bekleyen",
                            "value": 0
                        },
                        {
                            "label": "Reddedilen",
                            "value": 1
                        },
                        {
                            "label": "Onaylanan",
                            "value": 2
                        }
                    ]}
                />

            </StyledHeader>
            <Table
                size='middle'
                /* loading={loading} */
                dataSource={mainData}
                scroll={{
                    x: 800
                }}
                pagination={{
                    pageSize: 10,
                }}
                columns={[
                    {
                        "title": "Soru",
                        dataIndex: "question",
                        render: (row, record) => {
                            return (
                                <span>
                                    {record?.question}
                                </span>
                            )
                        },

                        ...getColumnSearchProps("question")
                    },
                    {
                        "title": "Toplam Kullanım",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.totalUsage}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Point",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.point}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Branş",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.branch}
                                </p>
                            )
                        },
                        dataIndex: "branch",
                        ...getColumnSearchProps("branch     ")

                    },
                    {
                        "title": "Ekleyen",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.user?.firstName} {record?.user?.lastName}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Durum",
                        render: (row, record) => {
                            return (
                                <p style={{ padding: "5px 8px", backgroundColor: "orange", borderRadius: "5px", width: "max-content", color: "white" }}  >
                                    {record.status}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Cevap Şıkları",
                        render: (row, record) => {
                            return (
                                <Button type='primary' onClick={() => {
                                   
                                    /* setAnswerModal({
                                        visible: true,
                                        data: record?.branchQuestionAnswers,
                                        label: record?.question
                                    }) */
                             
                                    setEditMode({
                                        visible: true,
                                        data: record
                                    })
                                }} >
                                    <FaEye />
                                </Button>
                            )
                        }
                    },
                    {
                        "title": "İşlem Yap",
                        render: (row, record) => {
                            return (
                                <div style={{ display: "flex", columnGap: "10px" }}>
                                    <Button type='primary' onClick={() => {
                                        AntModal.confirm({
                                            title: "Aktif etmek istediğinizde emin misiniz ?",
                                            cancelText: "İptal",
                                            okText: "Eminim ",
                                            onOk: () => {
                                                updateStatus(row.id, 2)
                                            }
                                        })
                                    }}  >
                                        Onayla
                                    </Button>
                                    <Button danger type='primary' onClick={() => {
                                        AntModal.confirm({
                                            title: "Reddetmek  istediğinizde emin misiniz ?",
                                            cancelText: "İptal",
                                            okText: "Eminim ",
                                            onOk: () => {
                                                updateStatus(row.id, 1)
                                            }
                                        })
                                    }}>
                                        Reddet
                                    </Button>
                                </div>
                            )
                        }
                    }
                ]}
            />
            <EgitmenSoruGuncelle refreshData={() => {
                getQuestions(activeStatusType)
                getAllBranchList()

            }} editMode={editMode} setEditMode={setEditMode} branchList={branchList} />
            <Modal isOpen={answerModal.visible} size='lg'  >
                <StyledModalHeader>
                    {answerModal.label}
                </StyledModalHeader>
                <ModalBody>
                    <StyledAnswerContainer>
                        {
                            answerModal.data?.length !== 0 ? answerModal.data?.map(item => {
                                return (
                                    <>
                                        <StyledAnswerInputItem disabled={true} correct={item.status} value={item.answer} style={{ resize: "none" }} />
                                    </>
                                )
                            }) : (
                                <Alert type='warning' message="Bu soru için cevap şıkkı eklenmemiş" style={{ width: "100%" }} />
                            )
                        }

                    </StyledAnswerContainer>

                </ModalBody>
                <ModalFooter>

                    <Button type='primary' danger onClick={() => {
                        setAnswerModal({
                            visible: false,
                            data: [],
                            label: ""
                        })
                    }}  >
                        İptal
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default SoruHavuzu


const StyledSelect = styled(Select)`
    width: 200px;
`
const StyledHeader = styled.header`
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px dashed gray;
    justify-content: flex-start;
    border-radius: 10px;
`

const StyledAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
`

const StyledAnswerItem = styled.div`
    display: flex;
    border:  1px solid gray;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
`

const StyledModalHeader = styled(ModalHeader)`

    width: 100%;
    h5 {
        width: 100%;
        text-transform: capitalize;
      /*   border: 1px solid gray;
        border-radius: 10px;
        padding: 10px; */
    }
`

const StyledAnswerInputItem = styled(Input.TextArea)`

    background-color: ${props => props.correct ? "green" : "white"} !important;
    color: ${props => props.correct ? "white" : "black"} !important ;
    font-weight: bold !important;
`
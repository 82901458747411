import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { FcStatistics } from "react-icons/fc";
import { axiosInstance } from '../../api/axiosInstance';
import { Select, Skeleton } from 'antd';

const AdminStatisticDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [courseList, setCourseList] = useState([])
    const [statisticData, setStatisticData] = useState({
        "totalRegisterCount": "",
        "totalLimitCount": "",
        "totalCourseRegisterCount": "",
        "totalCourseRegisterDeleteCount": ""
    })
    const [selectedCourse, setSelectedCourse] = useState()

    const getAllCourseList = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/courses/GetAll?type=Online&status=false", {
                page: 0,
                pageSize: 5
            })
            const activeCourseList = await axiosInstance.post("/courses/GetAll?type=Online&status=false", {
                page: 0,
                pageSize: response.data.count
            })
            /*  const response2 = await axiosInstance.post("/courses/GetAll?type=Online&status=true", {
                 page: 0,
                 pageSize: 5
             })
             const inactiveCourseList = await axiosInstance.post("/courses/GetAll?type=Online&status=true", {
                 page: 0,
                 pageSize: response2.data.count
             }) */
            const result = [...activeCourseList.data.items]
            setCourseList(result)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const getStatistic = async (id = 0) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`/Users/GetStatistics/${id}`)

            setStatisticData(response.data)
        }
        catch (err) {


        }
        finally {
            setLoading(false)

        }
    }

    const selectedCourseName = useMemo(() => {
        let course = courseList?.find(el => el?.id == selectedCourse)
        return course?.name
    }, [selectedCourse, courseList])

    useEffect(() => {
        getAllCourseList()
        getStatistic()
    }, [])

    /* if (loading) {
        return (
            <div style={{ padding: "20px" }}>
                <Skeleton active={true} />
            </div>
        )
    } */


    return (
        <StyledContainer>
            <StyledHeader>
                <StyledSelect
                    loading={loading}
                    placeholder="Kurs seçiniz"
                    showSearch
                    options={courseList?.map((item) => {
                        return {
                            "label": `${item?.name}-${item?.id}`,
                            "value": `${item?.id}`
                        }
                    })}
                    onChange={(e) => {
                        setSelectedCourse(e)
                        getStatistic(e)
                    }}
                    filterOption={(input, option) => {
                        return (
                            option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0

                        )
                    }}
                />
            </StyledHeader>
            {
                loading ? (
                    <div style={{ marginTop: "10px" }}>
                        <Skeleton active={true} />
                    </div>
                ) : (
                    <StyledCardContainer>
                        <StyledCardElement   >
                            <FcStatistics className='statistic_card_icon' />
                            {/* <img src={Frame322} alt="" className='image-header-info' /> */}
                            <div className='card_text_wrapper'>
                                <p className='number_text'>
                                    {statisticData?.totalLimitCount}
                                </p>
                                <p className='card_text_title'>Toplam Kontenjan (Bütün Kurslar) </p>
                            </div>
                        </StyledCardElement>
                        <StyledCardElement   >
                            <FcStatistics className='statistic_card_icon' />
                            {/* <img src={Frame322} alt="" className='image-header-info' /> */}
                            <div className='card_text_wrapper'>
                                <p className='number_text'>
                                    {
                                        statisticData?.totalRegisterCount
                                    }
                                </p>
                                <p className='card_text_title'>Toplam Kayıtlı Öğrenci (Bütün Kurslar) </p>
                            </div>
                        </StyledCardElement>
                        <StyledCardElement   >
                            <FcStatistics className='statistic_card_icon' />
                            {/* <img src={Frame322} alt="" className='image-header-info' /> */}
                            <div className='card_text_wrapper'>
                                <p className='number_text'>
                                    {statisticData?.totalCourseRegisterCount}

                                </p>
                                <div className='card_text_title'>
                                    <span>
                                        Kayıtlı Öğrenci
                                    </span>
                                    {
                                        selectedCourseName && (
                                            <span>
                                                ( {`${selectedCourseName}`} )
                                            </span>
                                        )
                                    } </div>
                            </div>
                        </StyledCardElement>
                        <StyledCardElement   >
                            <FcStatistics className='statistic_card_icon' />
                            {/* <img src={Frame322} alt="" className='image-header-info' /> */}
                            <div className='card_text_wrapper'>
                                <p className='number_text'>
                                    {statisticData?.totalCourseRegisterDeleteCount}

                                </p>
                                <div className='card_text_title'>
                                    <span>
                                        Kayıt Silen Öğrenci
                                    </span>
                                    {
                                        selectedCourseName && (
                                            <span>
                                                ( {`${selectedCourseName}`} )
                                            </span>
                                        )
                                    } </div>
                            </div>
                        </StyledCardElement>
                    </StyledCardContainer>

                )
            }


        </StyledContainer>
    )
}

export default AdminStatisticDashboard


const StyledContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media screen and (max-width:600px) {
        padding: 10px;
    }

`

const StyledHeader = styled.header`
    display: flex;
    border-bottom:  1px dashed gray;
    padding-bottom: 10px;
`

const StyledCardContainer = styled.div`
    display: grid;
    grid-template-columns:  1fr 1fr ;
    row-gap: 30px;
    column-gap: 20px;
    width: 100%;
  
    @media screen and (max-width:800px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width:600px) {
        grid-template-columns: 1fr;
        column-gap: 0;
    }

`
const StyledCardElement = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    column-gap: 30px;
    background-color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.45);
    border-radius: 5px;
    box-sizing: border-box;

    flex-wrap: wrap; /* Küçük ekranlarda elemanların sarılmasını sağlar */

    @media screen and (max-width:600px) {
       /*  column-gap: 10px;
        flex-direction: column;  */
    }

    .card_text_wrapper{
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        @media screen and (max-width:600px) {
            padding-left: 10px;
        }

        .number_text{
            font-size: 20px;
            font-weight: bold;
            color: #1D2026;;
        }

        .card_text_title{
            display: flex;
            align-items: center;
            column-gap: 5px;
            color: #4E5566;
            font-weight: 500;
            white-space: nowrap;
            font-weight: 600;
            margin-right: 20px;
        }
    }

    .statistic_card_icon{
        font-size: 40px;
    }
`;


const StyledSelect = styled(Select)`
    width: 100%;
    height: 40px;
`
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { GetAllBranch, createBranch, deleteBranchApi, getAllBranch, updateBranchApi } from '../../api/Branch';
import { MdModeEdit } from 'react-icons/md';
import { FiTrash } from 'react-icons/fi';
import { Input as AntInput, Button as AntButton, Table as AntTable } from "antd"
import DataTable from 'react-data-table-component';
import PageLoader from '../Common/PageLoader';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from "yup"
import { toast } from 'react-toastify';
import "./BranchTable.scss"
import styled from 'styled-components';
import { axiosInstance } from '../../api/axiosInstance';
import AddBranchModal from './AddBranchModal';

const BranchTable = () => {
    const [branch, setBranch] = useState([])
    const navigate = useNavigate()

    const [deleteModalShow, setDeleteModalShow] = useState({
        branchId: "",
        show: false
    })

    const [searchTerm, setSearchTerm] = useState("");
    const [modalShow, setModalShow] = useState({
        type: "create",
        show: false
    })


    const fetchBranches = async () => {
        try {
            const branches = await getAllBranch({
                page: 0,
                pageSize: 1000
            });

            setBranch(branches.data.items);
        } catch (err) {
            console.error("Error fetching branches:", err);
        }
    };

    const filteredData = branch?.filter((item) => {
        const lowercaseSearchTerm = searchTerm.toLowerCase();
        return item?.name.toLowerCase().includes(lowercaseSearchTerm);
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            description: "",
            tag: "",
            categoryId: ""
        },
        validationSchema: yup.object({
            name: yup.string().required("İsim Giriniz"),
            categoryId: yup.string().required("Kategori seçiniz"),
            tag: yup.string().required("Etiket Giriniz"),
            description: yup.string().required("Açıklama giriniz")
        }),
        onSubmit: async (value, { resetForm }) => {
            try {
                if (modalShow.type == "create") {

                    const response = await createBranch({
                        name: value.name,
                        description: value.description,
                        label: value.tag,
                        categoryId: value.categoryId
                    })
                    toast.success("branş kayıt edildi", {
                        autoClose: 1000
                    })
                    resetForm()
                    setModalShow({
                        type: "create",
                        show: false
                    })
                    await fetchBranches()
                }
                else {

                    const response = await updateBranchApi({
                        id: value.id,
                        name: value.name,
                        description: value.description,
                        label: value.tag,
                        categoryId: value.categoryId
                    })
                    fetchBranches();
                    /* setBranch(branch.map(el => {
                        if (el.id == value.id) {
                            return {
                                id: el.id,
                                name: value.name
                            }
                        }
                        return el
                    })) */
                    toast.success("branş güncellendi", {
                        autoClose: 1000
                    })
                    resetForm()
                    setModalShow({
                        type: "create",
                        show: false
                    })
                    await fetchBranches()
                }
            }
            catch (err) {
                toast.error(err.response.data.Detail, {
                    autoClose: 1500
                })
                resetForm()
            }

        }
    })



    const getBranchElementById = async (id) => {
        try {
            const response = await axiosInstance.get(`/branches/getById/${id}`)

            formik.setFieldValue("name", response?.data?.name)
            formik.setFieldValue("id", response.data?.id)
            formik.setFieldValue("categoryId", response?.data?.categoryId)
            formik.setFieldValue("description", response?.data.description)
            formik.setFieldValue("tag", response.data?.label)
            setModalShow({
                type: "edit",
                show: true
            })
        }
        catch (err) {

        }
    }



    useEffect(() => {
        fetchBranches();

    }, [])



    return (
        <>
            <div style={{ paddingTop: 10 }} >
                <StyledHeader >
                    <StyledSearchInput
                        type="text"
                        placeholder="Arama yap..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <StyledAddBranchBtn type='primary' onClick={() => {
                        navigate("/panel/brans/ekle")
                        /* setModalShow({
                            show: true,
                            type: "create"
                        }) */
                    }}>
                        Branş Ekle
                    </StyledAddBranchBtn>


                </StyledHeader>
                <AntTable
                    dataSource={filteredData}
                    scroll={{
                        x: 800
                    }}
                    size='middle'
                    pagination={{
                        pageSize: 10
                    }}
                    columns={[
                        {
                            title: "İsim",
                            render: (row, record) => {
                                return (
                                    <span style={{ textTransform: "capitalize" }} > {record?.name} </span>
                                )
                            }
                        },
                        {
                            title: "İşlem",
                            render: (row, record) => {
                                return (
                                    <div className="" style={{ display: "flex", columnGap: "10px" }} >
                                        <Button color="warning" onClick={() => {
                                            navigate(`/panel/brans/duzenle/${record?.id}`)
                                   
                                        }} >
                                            <MdModeEdit />
                                        </Button>
                                        <Button color='danger' onClick={async () => {
                                            setDeleteModalShow({
                                                branchId: record.id,
                                                show: true
                                            })
                                        }} >
                                            <FiTrash />
                                        </Button>

                                    </div>
                                )
                            }
                        }
                    ]}
                />

                <AddBranchModal formik={formik} modalShow={modalShow} setModalShow={setModalShow} />

                <Modal isOpen={deleteModalShow.show}>
                    <ModalHeader>
                        Onay Formu
                    </ModalHeader>
                    <ModalBody>
                        Bu branşı silmek istediğinize emin misiniz ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' onClick={async () => {
                            try {
                                await deleteBranchApi(deleteModalShow.branchId)

                                setBranch(branch.filter(el => el.id !== deleteModalShow.branchId))

                                setDeleteModalShow({
                                    show: false,
                                    branchId: ""
                                })
                                toast.success("Silme işlemi başarılı", {
                                    autoClose: 1000
                                })
                            }
                            catch (err) {
                                toast.error(err.response.data.message, {
                                    autoClose: 1500
                                })
                                setDeleteModalShow({
                                    show: false,
                                    branchId: ""
                                })
                            }
                        }} >
                            Sil
                        </Button>
                        <Button onClick={() => {
                            setDeleteModalShow({
                                show: false,
                                branchId: ""
                            })
                        }}>
                            İptal
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>

        </>
    )
}

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: "100%";
    margin-bottom: 20px;
`
const StyledSearchInput = styled(AntInput)`
    width: 200px;
    height: 35px;   
    margin-left: 0 !important;

  border: 1px solid #3498db; /* Burada istediğiniz border rengini ve kalınlığını ayarlayabilirsiniz */

    @media screen and (max-width:500px) {
        width: 150px;
    }
`

const StyledAddBranchBtn = styled(AntButton)`
    height: 35px;
`


export default BranchTable


/* The Fetching process for the media resource was aborted by the user agent at users request */
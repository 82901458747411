import React, { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import EditStudentInfoTab from './EditStudentInfoTab'
import StudentTakeCourse from './StudentTakeCourse'
const UpdateStudentForm = ({ setTitle }) => {
    const [activeTab, setActiveTab] = useState(1)
    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => setActiveTab(1)} >
                        Bilgiler
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={activeTab == 2 ? { backgroundColor: "#088F7D", color: "white" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => { setActiveTab(2) }} >
                        Aldığı Kurslar
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink
                        style={activeTab == 3 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => { setActiveTab(3) }} >
                        Sertifikalar
                    </NavLink>
                </NavItem> */}
            </Nav>
            <TabContent activeTab={activeTab} style={{ paddingTop: "20px" }}>
                <TabPane tabId={1}  >
                    <EditStudentInfoTab setTitle={setTitle} />
                </TabPane>
                <TabPane tabId={2} >
                    <StudentTakeCourse />
                </TabPane>
               {/*  <TabPane tabId={3} >
                    sertifika
                </TabPane> */}
            </TabContent>
        </>
    )
}

export default UpdateStudentForm
import React, { useEffect, useMemo, useState } from 'react'
import styled from "styled-components"
import { getAllReceivedNotification, getAllSendNotifications } from '../../api/Notifications'
import { getDetailCourseApi } from '../../api/Course'
import { useNavigate } from 'react-router-dom'
const TeacherAllMessageDashboard = ({ courseInfo }) => {
    const [loading, setLoading] = useState(false)
    const [receivedMessage, setReceivedMessage] = useState([])
    const navigate = useNavigate()

    const getMessageList = async () => {

        const receivedMessage = await getAllReceivedNotification({
            page: 0,
            pageSize: 1000
        })
        let sortDataList = receivedMessage.data.items?.sort((a, b) => {
            return new Date(a.createdAt) - new Date(b.createdAt)
        })?.reverse()
 
        setReceivedMessage(sortDataList)
    }

    useEffect(() => {
        getMessageList()
    }, [])

    return (
        <StyledMainContainer>
            <MessageContainer>
                {
                    receivedMessage.map((item, index) => {
                        return (
                            <StyledMessageElement key={`${index}`} onClick={() => {
                                navigate(`/panel/egitmen/mesaj?userId=${item?.sentUserId}`)
                            }} >
                                <p className='student_info' >
                                    {item?.sentUser}
                                </p>
                                <p className='user_msg' >
                                    {item?.message}


                                </p>
                            </StyledMessageElement>
                        )
                    })
                }

            </MessageContainer>
        </StyledMainContainer>
    )
}

export default TeacherAllMessageDashboard



const StyledMainContainer = styled.div`
    display: flex;
    padding: 10px;

    @media screen and (max-width:600px) {
               padding: 5px;
            }
`

const MessageContainer = styled.div`
    height: 600px;
    width: 100%;
    padding-top: 30px;
  /*   border: 1px solid gray; */
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    overflow-y:scroll;
`

const StyledMessageElement = styled.div`
    padding: 10px;
    background-color: #33A293;
    max-width: 900px;
    border-radius:10px ;
    border-top-left-radius: 0;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    .user_msg{
        color: white;
        padding-top: 5px;
        font-size: 12px;
        @media screen and (max-width:600px) {
               padding-top: 15px;
            }
    }

    .student_info{
            position: absolute;
            top: -20px;
            left: 0;
            border-radius: 5px;
            border-bottom-left-radius: 0;
            padding: 3px 10px;
            color: white;
            background-color: #277CB5;


            @media screen and (max-width:600px) {
                top: -20px
            }
         }
    
`
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BsArrowDown } from 'react-icons/bs'
import { Card } from 'reactstrap'
import styled from 'styled-components'
import { getAllForExamIdApi } from '../../../api/exam'
import { useParams } from 'react-router-dom'
import { Table as AntTable, Input, Button as AntButton } from "antd"

const ExamResultDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const { id } = useParams()

    const getStudentResults = async () => {
        try {
            const response = await getAllForExamIdApi(id)
          
            setData(response.data.items)
        }
        catch (err) {

        }
    }

    const columns = [
        {
            "name": "İsim",
            "selector": (row) => {
                return (
                    <p>
                        {row?.userFistAndLastName}
                    </p>
                )
            }
        },
        {
            name: "Puan",
            sortable: true,
            sortFunction: (a, b) => a.totalPoint - b.totalPoint,
            "selector": (row) => {
                return (
                    <p>
                        {row?.totalPoint}
                    </p>
                )
            }
        }
    ]


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm()
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    useEffect(() => {
        getStudentResults()
    }, [])

    return (
        <StyledContainer>
            <AntTable
                size='middle'
                dataSource={data}
                pagination={{
                    pageSize: 10
                }}
                scroll={{
                    x: 800
                }}
                columns={[
                    {
                        title: "İsim",
                        dataIndex: "userFistAndLastName",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.userFistAndLastName}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("userFistAndLastName"),
                        sorter: (a, b) => a?.userFistAndLastName?.localeCompare(b?.userFistAndLastName),
                    },
                    {
                        title: "Puan",
                        dataIndex: "totalPoint",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.totalPoint}
                                </p>
                            )
                        },
                          sorter: (a, b) => a?.totalPoint - b?.totalPoint
                    }
                ]}
            />
        </StyledContainer>

    )
}

const StyledContainer = styled.div`
    margin-top: 20px;
`

export default ExamResultDashboard
import { Select, Table, Button, Input } from 'antd'
import React, { useRef, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'
import { useNavigate } from 'react-router-dom'
import { MdClear } from 'react-icons/md'
import { formatDate } from 'date-fns'
import trLocale from "date-fns/locale/tr";
import { FaEdit } from 'react-icons/fa'

const VideoEgitimDetayDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(false)
    const [allCourse, setAllCourse] = useState([])
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const filtersRef = useRef({ clearFilters: () => { }, confirm: () => { } });
    const [selectedYear, setSelectedYear] = useState(null);

    const getAllCourse = async (statusType = false) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post(`/courses/getall?type=Offline&status=false`, {
                page: 0,
                pageSize: 1
            })
            const response2 = await axiosInstance.post(`/courses/getall?type=Offline&status=false`, {
                page: 0,
                pageSize: response.data.count
            })
            setAllCourse(response2.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const convertTrFormat = (time) => {
        return formatDate(new Date(time), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = () => {
        const { clearFilters, confirm } = filtersRef.current;
        if (clearFilters) {
            clearFilters();
            setSearchText('');
            confirm();
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            // clearFilters ve confirm fonksiyonlarını referansa kaydet
            filtersRef.current = { clearFilters, confirm };

            return (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Ara
                    </Button>
                    <Button onClick={() => handleReset()} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            );
        },
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    const mainData = useMemo(() => {
        if (selectedYear) {
            return allCourse.filter(item => {
                const specDate = new Date(item.startDate)
                const year = specDate.getFullYear()

                return year == selectedYear
            })
        }
        return allCourse
    }, [allCourse, selectedYear])

    useEffect(() => {
        getAllCourse()
    }, [])

    return (
        <StyledContainer>
            <StyledHeader>

                <Button danger type='primary' onClick={() => {
                    handleReset()
                    setSelectedYear()
                }} style={{ display: "flex", alignItems: "center", columnGap: "5px" }} >
                    Filtreleri Temizle <MdClear />
                </Button>
            </StyledHeader>
            <Table
                loading={loading}
                dataSource={mainData}
                scroll={{
                    x: 700
                }}
                columns={[
                    {
                        title: "id",
                        dataIndex: "id",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.id}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("id")
                    },
                    {
                        title: "isim",
                        dataIndex: "name",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.name}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("name")
                    },

                    {
                        title: "Eğitmen",

                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.courseAdministrator ?? "Eğitmen Seçilmedi"}
                                </p>
                            )
                        },

                    },
                    {
                        title: "Kategori",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.category?.name}
                                </p>
                            )
                        },
       
                    },
                    {
                        title: "İşlem",
                        render: (row, record) => {
                            return (
                                <Button type='primary' onClick={() => {
                                    navigate(`/panel/kurs/duzenle/${record.id}`)
                                }} >
                                    <FaEdit />
                                </Button>
                            )
                        }
                    }
                ]}
            />
        </StyledContainer>
    )
}

export default VideoEgitimDetayDashboard

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

const StyledHeader = styled.div`
    display: flex;
    border-bottom: 1px dashed black;
    padding-bottom:10px;
    margin-bottom: 20px;
    column-gap: 10px;
`
const StyledSelect = styled(Select)`
    width: 200px;
`

const YearSelect = styled(Select)`
    width: 200px;
  
`
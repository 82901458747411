import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CourseAdminListGetAll, DynamicCourseSearch, addCourseAdministrators, getAllCourseByStatusApi } from '../../../api/Course'
import { toast } from 'react-toastify'
import { Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../context/user'
import art from "../../../assets/images/img1.png"
import { FaUsers } from "react-icons/fa";
import "./OfflineCourseDashboard.css"
import { Pagination } from 'antd'



const OfflineCourseDashboard = () => {
  const [courseData, setCourseData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false)
  const [context, dispatch] = useContext(UserContext)
  const [filterType, setFilterType] = useState("name")


  const getCourseList = async (page) => {
    try {
      setLoading(true)
      const response = await getAllCourseByStatusApi({
        page: page,
        pageSize: 1000
      }, "Offline")
      const responsev2 = await getAllCourseByStatusApi({
        page: page,
        pageSize: response.data.count
      }, "Offline")


      let bolum = response.data.count / 8

      if (response.data.count % 8 !== 0) {
          bolum += 1
      }

      setCourseData(responsev2.data.items)
      setTotalCount(bolum)



    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }





  useEffect(() => {
    getCourseList(0)
  }, [])


  const permissionRole = useMemo(() => {
    const role = context.role.replace(/\s+/g, '')
    return role == "SüperAdmin" || role == "Admin"
  }, [context])


  const dynamicSearch = async (e) => {
    try {

      if (e !== "") {
        const body = {
          "sort": [
            {
              "field": "name",
              "dir": "asc"
            }
          ],
          "filter": {
            "field": filterType,
            "operator": "contains",
            "value": e,
          }
        }

        const response = await DynamicCourseSearch(body, {
          page: 0,
          pageSize: 10,
        }
        )
        const selected = response.data.items?.filter(x => x.type == "Offline")
   
        setCourseData(selected ?? [])
      }
      else {
        getCourseList(0)
      }
    }
    catch (err) {

    }
  }


  return (
    <>

      <Row className="mb-2">
        <Col lg={2} style={{ marginBottom: "10px" }} >
          <Input
            width={"%50"}
            type="text"
            placeholder="Arama yap..."
            onChange={(e) => {
              dynamicSearch(e.target.value)
            }}
          />
        </Col>
        <Col lg={10}>
          {
            permissionRole && (
              <div style={{ display: "flex", justifyContent: "flex-end", }}>
                <Button color="primary" onClick={() => {
                  navigate("/panel/cevrimdisi/ekle")
                }}>
                  Çevrimdışı Eğitim Ekle
                </Button>
              </div>
            )
          }

        </Col>
      </Row>


      <div className='active_course_dashboard_wrapper' >
        <div className='active_course_container'  >
          {
            courseData.map((el) => {
              return (
                <div className='active_course_item' onClick={() => {
                  navigate(`/panel/kurs/${el.id}`)
                }} >
                  <img src={art} alt="" className='active_course_item_image' />
                  <div className='active_course_item_content' >
                    <h6 className='active_course_item_content_title' >
                      {el.name}
                    </h6>
                  </div>
                  <div className='active_course_item_footer'>
                    <p className='active_course_item_footer_semester'>
                      {el.semester}
                    </p>
                    <div className='active_course_item_footer_limit' >
                      <FaUsers size={20} color='#80949F' />
                      <span  >
                        {el.limit}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })
          }

        </div>
        <div className='active_course_table_pagination' >
          <Pagination total={totalCount} pageSize={1} onChange={(e) => {
            getCourseList(e - 1)
          }} />
        </div>
      </div>






      {/* <DataTable
        data={courseData}
        columns={columns}
        pagination
        paginationTotalRows={totalCount}
        paginationServer
        onChangePage={(e) => {
          getCourseList(e - 1)
        }}
        noDataComponent={
          <Card className="w-100 p-5">
            <center>
              <h2>Herhangi bir veri bulunamadı.</h2>
            </center>
          </Card>
        }
        paginationComponentOptions={{
          rowsPerPageText: "Satır Sayısı",
          rangeSeparatorText: "-",
          noRowsPerPage: true
        }}
      /> */}
    </>
  )
}

export default OfflineCourseDashboard
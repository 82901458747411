import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Input, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import { GetTeacherListApi, getTeacherListApi } from "../../../api/Teacher";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getDynamicUserSearchApi, getUserListApi } from "../../../api/UserApi";
import "./TeacherTable.scss"
import { Select, Input as AntdInput, Button as AntButton, Table as AntTable, TreeSelect } from "antd";
import styled from "styled-components";
import { getAllBranch } from "../../../api/Branch";


const Index = ({ setTitle }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate()
    const [teacherList, setTeacherList] = useState([])
    const [filterType, setFilterType] = useState("firstName")
    const [totalCount, setTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectedPage, setSelectedPage] = useState(1)
    const [allBranch, setAllBranch] = useState([])
    const staticPageNumber = 10

    const getTeacherList = async (page) => {
        try {
            setLoading(true)
            const response = await getUserListApi({
                page: page,
                pageSize: 10,
                roleId: 2
            })
            setTeacherList(response.data.items)

            setTotalCount(response.data.count)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const getAllTeacher = async () => {
        try {
            setLoading(true)
            const response = await getUserListApi({
                page: 0,
                pageSize: 10,
                roleId: 2
            })
            const responsev2 = await getUserListApi({
                page: 0,
                pageSize: response.data.count,
                roleId: 2
            })

            const formatTeacherList = responsev2.data?.items?.map(item => {
                const { branches, ...rest } = item
                let branchList = ""
                branches.forEach((el, index) => {
                    if (branches?.length - 1 !== index) {
                        branchList += ` ${el} - `
                    }
                    else {
                        branchList += `${el}`
                    }

                })
                return {
                    ...rest,
                    branches: branchList
                }
            })
            setTeacherList(formatTeacherList)
            return responsev2?.data?.items
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const AllBranchRequest = async () => {
        try {
            const response = await getAllBranch({
                page: 0,
                pageSize: 1
            })
            const responsev2 = await getAllBranch({
                page: 0,
                pageSize: response.data.count
            })

            setAllBranch(responsev2.data.items.map(el => {
                return {
                    "label": el.name,
                    "value": el.id
                }
            }))
        }
        catch (err) {

        }
    }


    useEffect(() => {
        getAllTeacher()
        /*  getTeacherList(0) */
        AllBranchRequest()
    }, [])

    const dynamicSearch = async (e, page = 0) => {
        try {
            setLoading(true)
            if (e !== "") {
                const body = {
                    "sort": [
                        {
                            "field": "firstname",
                            "dir": "asc"
                        }
                    ],
                    "filter": {
                        "field": filterType,
                        "operator": "contains",
                        "value": e,
                    }
                }

                const response = await getDynamicUserSearchApi(body, {
                    page: page,
                    pageSize: staticPageNumber,
                    roleId: 2
                }
                )
                const selected = response.data.items
                setTeacherList(selected)
                setTotalCount(response.data.count)
            }
            else {
                getTeacherList(0)
            }
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const teacherListData = useMemo(() => {
        return teacherList
    }, [teacherList])

    /* const mainTeacherData = useMemo(() => {
        const branchControl = allBranch.find(el => el.value == filterType)
        if (branchControl) {
            return teacherList.filter(item => {
                const fullName = (item.firstName + item.lastName).toLowerCase();
                return fullName.includes(searchTerm.toLowerCase());
            })
        }
        else {
            return teacherList
        }

    }, [teacherList, allBranch, filterType, searchTerm]) */


    const [searchTextName, setSearchTextName] = useState("")
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchTextName(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchTextName('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <AntdInput
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset

                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });



    return (
        <div className="teacher_table_main_container" >
            <div className="teacher_table_filter_header">
                <AntButton type="primary" onClick={() => {
                    navigate("/panel/egitmen/ekle")
                }} >
                    Eğitmen Ekle
                </AntButton>
            </div>
            <AntTable
                bordered={true}
                loading={loading}
                scroll={{
                    x: 800
                }}
                size="middle"

                dataSource={teacherListData}
                columns={[
                    {
                        title: "Ad",
                        dataIndex: "firstName",
                        render: (row, record) => {
                            return (
                                <span> {record?.firstName} </span>
                            )
                        },
                        sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
                        ...getColumnSearchProps("firstName")
                    },
                    {
                        title: "Soyad",
                        dataIndex: "lastName",
                        render: (row, record) => {
                            return (
                                <span> {record?.lastName} </span>
                            )
                        },
                        sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName),
                        ...getColumnSearchProps("lastName")
                    },
                    {
                        title: "Branş",
                        dataIndex: "branches",
                        render: (row, record) => {
                            return (
                                <span> {record?.branches} </span>
                            )
                        },
                        sorter: (a, b) => a?.email?.localeCompare(b?.email),
                        ...getColumnSearchProps("branches")
                    },
                    {
                        title: "Telefon",
                        dataIndex: "phone",
                        render: (row, record) => {
                            return (
                                <span> {record?.phone} </span>
                            )
                        },
                        ...getColumnSearchProps("phone")
                    },
                    {
                        title: "Tc No",
                        dataIndex: "tc",
                        render: (row, record) => {
                            return (
                                <span>
                                    {record?.tc}
                                </span>
                            )
                        },
                        ...getColumnSearchProps("tc")
                    },

                    {
                        title: "İşlem",
                        render: (row, record) => {
                            return (
                                <AntButton type="primary" onClick={() => {
                                    navigate(`/panel/egitmen/duzenle/${record.id}`)
                                }} >
                                    <MdModeEdit />
                                </AntButton>
                            )
                        }
                    }
                ]}
            />

        </div>
    );
};


export default Index

const StyledSelectInput = styled(Select)`
  width: 150px;
  height: 35px;
`

const StyledAntInput = styled(AntdInput)`
    height: 35px;
    width: 200px;
    @media screen and (max-width: 450px) {
   width: 150px;
}
    @media screen and (max-width: 365px) {
   width: 100px;
}
    @media screen and (max-width: 310px) {
   width: 150px;
   margin-top:10px;
}
`